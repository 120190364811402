import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {connect} from 'react-redux';
import moment from 'moment';
import {toast} from 'react-toastify';
import * as Actions from '../../../store/actions';
import {
  DashboardLayout,
  EmptyState,
  Filter,
  FilterButton,
  PageLimit,
  Pagination,
  SearchInput,
  TableLayout,
  SkeletonLoader,
  Loader,
} from '../../../components';
import {
  corruptedFile,
  eye,
  failed,
  lookupEmpty,
  messagesIcon,
  messagingReportBreadcrumb,
  successful,
} from '../../../assets/images/images';
import {
  filterOutEmptyValues,
  mapToOptionsArray,
  parseUrlParams,
} from '../../../helpers';
import {fetchReport} from '../../../requests/queries/report';
import {PageContext} from '../../../helpers/context';
import {useAllApps} from '../../../hooks/useApps';
import {useNavigate} from 'react-router-dom';
import LogDetailsModal from './LogDetailsModal';

const messageTypes = [
  {value: 'sms', label: 'sms', name: 'msg_type'},
  {value: 'whatsapp', label: 'whatsapp', name: 'msg_type'},
  {value: 'sms_otp', label: 'sms_otp', name: 'msg_type'},
];

const defaultFilterOptions = [
  // {
  //   title: 'App Name',
  //   name: 'app_id',
  //   values: [],
  // },
  // {
  //   title: 'Message Type',
  //   name: 'msg_type',
  //   values: [],
  // },
];

function MessagingReport() {
  const navigate = useNavigate();

  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {setPageName} = useContext(PageContext);

  useEffect(() => {
    setPageName('messagingreport');
  }, [setPageName]);

  const {userApps} = useAllApps();

  const allApps = userApps?.map(app => app.value)?.join(',');

  const filteredApps = sessionStorage.getItem('filtered_apps');

  const {data: messagingReport, isLoading} = useQuery(
    [
      'messaging-report',
      {page: currentPage, limit, allApps, filteredApps, ...filters},
    ],
    () =>
      fetchReport(filteredApps ?? allApps, {
        page: currentPage,
        limit,
        ...filters,
      }),
    {
      onSuccess: data => {
        toast.success(data.message);
        setFilters({
          ...filters,
          download: undefined,
        });
      },
      onError: error => {
        toast.error(error.response.data.error);
      },
      enabled: !!allApps,
      retry: 1,
    },
  );

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const messagingReportData = useMemo(
    () => (!isLoading && messagingReport?.rows) || [],
    [isLoading, messagingReport?.rows],
  );

  const messagingReportOverview = messagingReport?.overview || [];
  const totalMessagesSent =
    messagingReportOverview.find(item => item.status === 'sent')?.count || 0;
  const totalMessagesFailed =
    messagingReportOverview.find(item => item.status === 'failed')?.count || 0;
  const totalMessages = totalMessagesSent + totalMessagesFailed;

  useEffect(() => {
    const apiParams = parseUrlParams();
    apiParams.limit = limit;

    const searchParams = new URLSearchParams(document.location.search);
    const pageParam = searchParams.get('page');
    if (pageParam) {
      setCurrentPage(pageParam);
    }

    const searchParam = searchParams.get('search');

    if (searchParam) {
      apiParams.search = searchParam;
    }

    if (
      !apiParams.date_from &&
      !apiParams.date_to &&
      (!apiParams.search || apiParams.search === '')
    ) {
      setFiltersApplied(false);
    } else {
      setFiltersApplied(true);
    }

    if (Object.keys(apiParams).length > 1) {
      setFilters(apiParams);
    }

    window.addEventListener('beforeunload', () => {
      sessionStorage.removeItem('filtered_apps');
    });

    return () => {
      sessionStorage.removeItem('filtered_apps');
    };
  }, [limit]);

  const filterOptions = useMemo(() => {
    if (!userApps) {
      return defaultFilterOptions;
    }

    return [
      {
        title: 'Apps',
        name: 'apps',
        values: mapToOptionsArray(userApps),
      },
      {
        title: 'Message Type',
        name: 'msg_type',
        values: messageTypes,
      },
    ];
  }, [userApps]);

  const handleFilter = (selectedOptions, shouldDownload = false) => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);

    const {msg_type} = filteredOptions;

    if (filteredOptions.start) {
      filteredOptions.date_from = filteredOptions.start;
      delete filteredOptions.start;
    }

    if (filteredOptions.end) {
      filteredOptions.date_to = filteredOptions.end;
      delete filteredOptions.end;
    }

    if (filteredOptions.apps) {
      const appIds = filteredOptions.apps.map(app => app).join(',');
      sessionStorage.setItem('filtered_apps', appIds);
    }

    if (Array.isArray(msg_type)) {
      filteredOptions.msg_type = msg_type.join(',');
    }

    setCurrentPage(1);

    if (shouldDownload) {
      filteredOptions.download = true;
    }

    const {apps, ...rest} = filteredOptions;
    setFilters(rest);
    setFiltersApplied(true);
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    navigate('');
    setCurrentPage(1);
    setFilters(defaultFilterOptions);
    setFiltersApplied(false);
    setOpenFilter(true);
    sessionStorage.removeItem('filtered_apps');
  };

  const isFiltered =
    messagingReportData?.length === 0 && !isLoading && filtersApplied;

  return (
    <DashboardLayout
      bg="bg-white80"
      xlLeftMargin="xl:ml-12"
      xlRightPadding="xl:pr-[24px]"
      overFlow="overflow-none"
      breadCrumbs={
        <div className="flex items-center">
          <img src={messagingReportBreadcrumb} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1">Messaging Logs</p>
        </div>
      }
    >
      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={handleFilter}
        resetUrl={handleResetFilter}
        filterOptions={filterOptions}
      />

      <div className="flex flex-col lg:flex-row gap-6 lg:gap-0 lg:items-center justify-between">
        <h1 className="font-medium text-grey text-lg">Messaging Logs</h1>

        <div className="flex flex-col lg:flex-row lg:items-center gap-5">
          <SearchInput
            onChange={value => {
              const searchParams = new URLSearchParams(
                document.location.search,
              );

              if (value === '') {
                searchParams.delete('search');
              } else {
                searchParams.set('search', value);
                setFiltersApplied(true);
              }

              const newSearchParams = searchParams.toString();
              navigate(`?${newSearchParams}`);
              setFilters({...filters, search: value});
            }}
            className="outline-white80"
          />{' '}
          <div className="flex items-center gap-5">
            <FilterButton
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              className="h-[48px] justify-center w-[89px]"
            />

            {/* <PrimaryButton buttonText="Get Report" className="w-[99px]" /> */}
          </div>
        </div>
      </div>

      <div className="mt-6">
        <div className="flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-4 w-full mt-6">
          <div className="flex gap-2 lg:gap-4 lg:w-2/3">
            <div className="bg-white rounded-lg flex flex-col lg:flex-row lg:items-center gap-2 p-2 lg:p-6 w-1/2 lg:w-full">
              <img
                src={messagesIcon}
                alt=""
                className="lg:mb-3 w-6 h-6 lg:w-11 lg:h-11"
              />
              <div className="flex flex-col gap-1">
                <p className="text-body font-medium text-sm">Total Messages</p>
                {isLoading ? (
                  <SkeletonLoader style={{width: 80, height: 25}} />
                ) : (
                  <p className="font-semibold text-[28px] tracking-[-0.02em] text-grey">
                    {totalMessages}
                  </p>
                )}
              </div>
            </div>

            <div className="bg-white rounded-lg flex lg:flex-row flex-col lg:items-center gap-2 p-2 lg:p-6 w-1/2 lg:w-full">
              <img
                src={successful}
                alt=""
                className="lg:mb-3 w-6 h-6 lg:w-11 lg:h-11"
              />
              <div className="flex flex-col gap-1">
                <p className="text-body font-medium text-sm">
                  Total Messages Sent
                </p>
                {isLoading ? (
                  <SkeletonLoader style={{width: 80, height: 25}} />
                ) : (
                  <p className="font-semibold text-[28px] tracking-[-0.02em] text-grey">
                    {totalMessagesSent}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg flex flex-col lg:flex-row lg:items-center gap-2 p-2 lg:p-6 w-full lg:w-1/3">
            <img
              src={failed}
              alt=""
              className="lg:mb-3 w-6 h-6 lg:w-11 lg:h-11"
            />
            <div className="flex flex-col gap-1">
              <p className="text-body font-medium text-sm">
                Total Messages Not sent
              </p>
              {isLoading ? (
                <SkeletonLoader style={{width: 80, height: 25}} />
              ) : (
                <p className="font-semibold text-[28px] tracking-[-0.02em] text-grey">
                  {totalMessagesFailed}
                </p>
              )}
            </div>
          </div>
        </div>
        <p className="text-grey font-medium mt-6">Messaging Details</p>
        <TableLayout className="w-full mt-3">
          <thead className="text-small font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">date created</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">sender</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">recipient</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">message-type</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">message id</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <span className="flex items-center">status</span>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap"></th>{' '}
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colSpan={8}>
                {isLoading ? (
                  <Loader height={45} />
                ) : !isLoading &&
                  !filtersApplied &&
                  messagingReportData?.length === 0 ? (
                  // eslint-disable-next-line react/jsx-indent
                  <EmptyState
                    height={45}
                    body="Gain insight into your messaging activities."
                    src={lookupEmpty}
                    bodyMaxWidth="max-w-[203px]"
                    noBtn
                  />
                ) : isFiltered ? (
                  <EmptyState
                    height={45}
                    body="This filter did not return any data, try using different values."
                    src={corruptedFile}
                    noBtn
                    customBtn={
                      <button
                        onClick={handleResetFilter}
                        className="text-brandBlue p-4 text-sm font-medium"
                      >
                        Update preferences
                      </button>
                    }
                  />
                ) : null}
              </td>
            </tr>
          </tbody>

          <tbody className="text-sm text-body bg-white">
            {!isLoading &&
              messagingReportData?.map((msg, index) => (
                <tr
                  key={index}
                  className="text-left border-b cursor-pointer border-grey60"
                >
                  <td className="p-4 pl-12 whitespace-nowrap">
                    {moment(msg.created).format('MMMM D, YYYY, h:mm a')}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">{msg.sender}</td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {msg.recipient}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap uppercase">
                    {msg.msg_type}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {msg.message_id || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    <span
                      className={`uppercase w-fit rounded-full px-2 py-1 mt-1 h-5 flex items-center justify-center ${
                        msg.status === 'sent'
                          ? 'bg-[#F0F6EF] text-[#396C31]'
                          : 'bg-[#FCDFDF] text-[#F15D5F]'
                      } text-xs`}
                    >
                      {msg.status === 'sent' ? 'Sent' : 'Error'}
                    </span>
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    <img
                      src={eye}
                      alt="eye"
                      className="cursor-pointer"
                      onClick={() => {
                        setSelectedMessageId(msg.message_id);
                        setIsModalOpen(true);
                      }}
                    />{' '}
                  </td>
                </tr>
              ))}
          </tbody>
        </TableLayout>

        <LogDetailsModal
          open={isModalOpen}
          setOpen={setIsModalOpen}
          message_id={selectedMessageId}
        />

        {messagingReportData && messagingReportData.length > 0 && (
          <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
            <div className="flex items-center text-body text-tiny">
              <PageLimit
                onLimitChange={setLimit}
                total={messagingReport && messagingReport?.count}
                length={messagingReportData?.length}
                limit={limit}
              />
            </div>

            <div className="mb-8 sm:mb-0">
              <Pagination
                total={
                  messagingReportData &&
                  Math.ceil(messagingReport?.count / limit)
                }
                current={+currentPage}
                onPageChange={activePage => {
                  pageClick(activePage);
                  const searchParams = new URLSearchParams(
                    document.location.search,
                  );
                  searchParams.set('page', activePage);
                  const newSearchParams = searchParams.toString();
                  navigate(`?${newSearchParams}`);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(MessagingReport);
