import {createHashHistory} from 'history';
import {toast} from 'react-toastify';
import reduxApi from '../../helpers/reduxApi';
import {
  LOADING,
  BANK_CODES,
  LOOKUP_HISTORY,
  LOOKUP,
  BATCH_LOOKUP,
  LOOKUP_PAGE,
  DOWNLOADING,
  LOOKUP_APP_ID,
  H_LOADING,
  L_LOADING,
  UPDATED,
  SINGLE_BATCH,
  BUSINESS_LOOKUP_HISTORY,
  BATCH_BUSINESS_LOOKUP,
} from '../types';
import store from '../index';
createHashHistory();

export const INDIVIDUALS_MODEL_TYPES = [
  'ng_bvn',
  'ng_nin',
  'ng_phone',
  'ao_nin',
  'gh_voter',
  'ke_nin',
  'za_nin',
  'ng_dl',
  'gh_passport',
  'ke_kra_pin',
  'ke_passport',
  'ug_nin',
  'ng_nuban_status',
  'nin',
  'zm_nrc',
  'phone_advance',
];

export const BUSINESS_MODEL_TYPES = ['company_search', 'ng_cac'];

export const getLookupHistory = ({
  company_id,
  page,
  limit,
  status,
  model_type,
  country,
  mode,
  start,
  end,
  apps,
  download,
  caller_email,
  caller_name,
  search,
  model_types = INDIVIDUALS_MODEL_TYPES,
  documentUrl,
}) => {
  let modelTypeParam;

  if (!model_type) {
    modelTypeParam = `&model_type=${model_types.join('&model_type=')}`;
  } else {
    modelTypeParam = `&model_type=${model_type}`;
  }

  const apiParams = `company_id=${company_id}${page ? `&page=${page}` : ''}${
    limit ? `&limit=${limit}` : ''
  }${status ? `&status=${status}` : ''}${modelTypeParam ? modelTypeParam : ''}${
    country ? `&country=${country}` : ''
  }${start ? `&start=${start}` : ''}${end ? `&end=${end}` : ''}${
    mode ? `&mode=${mode}` : ''
  }${apps ? `&app_id=${apps}` : ''}${download ? `&download=${download}` : ''}${
    caller_email ? `&caller_email=${caller_email}` : ''
  }${caller_name ? `&caller_name=${caller_name}` : ''}${
    search ? `&search=${search}` : ''
  }`;

  return reduxApi(
    `/users/widget/lookup/${
      documentUrl ? documentUrl : 'individual'
    }?${apiParams}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        if (download) {
          toast.success(data.message);
          dispatch({payload: 'lookupData', type: H_LOADING});

          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          dispatch({payload: '', type: H_LOADING});
          const noFilter =
            !status && !model_type && !country && !apps && !start && !end;
          const lookupData =
            !noFilter && data.totalRecords === 0 ? undefined : data;
          dispatch({payload: lookupData, type: LOOKUP_HISTORY});
        }
        dispatch({payload: '', type: H_LOADING});
      },
    },
    {error: 'ERROR', loading: H_LOADING, responder: LOOKUP_HISTORY},
  );
};

export const getBusinessLookupHistory = ({
  company_id,
  page,
  limit,
  status,
  model_type,
  country,
  start,
  end,
  apps,
  download,
  caller_email,
  caller_name,
}) => {
  let modelTypeParam;

  if (!model_type) {
    modelTypeParam = `&model_type=${BUSINESS_MODEL_TYPES.join('&model_type=')}`;
  } else {
    modelTypeParam = `&model_type=${model_type}`;
  }

  const apiParams = `company_id=${company_id}${page ? `&page=${page}` : ''}${
    limit ? `&limit=${limit}` : ''
  }${status ? `&status=${status}` : ''}${modelTypeParam ? modelTypeParam : ''}${
    country ? `&country=${country}` : ''
  }${start ? `&start=${start}` : ''}${end ? `&end=${end}` : ''}${
    apps ? `&app_id=${apps}` : ''
  }${download ? `&download=${download}` : ''}${
    caller_email ? `&caller_email=${caller_email}` : ''
  }${caller_name ? `&caller_name=${caller_name}` : ''}`;

  return reduxApi(
    `/users/widget/lookup/individual?${apiParams}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        if (download) {
          toast.success(data.message);
          dispatch({payload: 'lookupData', type: H_LOADING});

          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          dispatch({payload: '', type: H_LOADING});
          const noFilter =
            !status && !model_type && !country && !apps && !start && !end;
          const lookupData =
            !noFilter && data.totalRecords === 0 ? undefined : data;
          dispatch({payload: lookupData, type: BUSINESS_LOOKUP_HISTORY});
        }
        dispatch({payload: '', type: H_LOADING});
      },
    },
    {error: 'ERROR', loading: H_LOADING, responder: BUSINESS_LOOKUP_HISTORY},
  );
};

export const fetchLookup = ({
  id,
  type,
  account_number,
  setResults,
  documentUrl,
}) =>
  reduxApi(
    `/users/widget/lookup/${
      documentUrl ? documentUrl : 'individual'
    }/${id}?type=${type}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: null, type: LOOKUP});
        const payload =
          type === 'document_process'
            ? setResults(data, type)
            : {
                [type]: {
                  ...data?.entity,
                  ...(type === 'nuban' && {account_number}),
                },
                type,
              };

        dispatch({
          payload,
          type: LOOKUP,
        });
      },
    },
    {error: 'ERROR', loading: LOADING, responder: LOOKUP},
  );

export const performLookup = ({
  data,
  type,
  appId,
  account_number,
  customer360,
  business360,
  navigate,
  queryClient,
  customer_id,
  setOpen,
  business_id,
}) =>
  reduxApi(
    `/users/widget/lookup${data}${
      business_id ? `&business_id=${business_id}` : ''
    }${customer_id ? `&customer_id=${customer_id}` : ''}${
      customer360 ? '&customer=true' : ''
    }`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: null, type: LOOKUP});

        if (
          ((customer_id || business_id) && (customer360 || business360)) ||
          data?.entity?.customer
        ) {
          queryClient.invalidateQueries();
          const route = customer360
            ? 'customer-360/government-lookup'
            : 'business-360/business-lookup';
          const ID = customer360
            ? customer_id || data?.entity?.customer
            : business_id || data?.entity?.business;

          setOpen(false);
          return navigate(`/${route}/${ID}`);
        }

        const user = store && store?.getState()?.auth?.user;

        let formattedEntity = data?.entity;
        if (type === 'global_search' && formattedEntity) {
          formattedEntity = [...formattedEntity];

          dispatch({
            payload: {
              [type]: {
                entity: formattedEntity,
                ...(type === 'nuban' && {account_number}),
                caller_name: `${user?.first_name} ${user?.last_name}`,
                caller_email: user?.email,
                date_created: `${new Date()}`,
              },
              type,
            },
            type: LOOKUP,
          });
          dispatch({payload: appId, type: LOOKUP_APP_ID});
          dispatch({type: LOOKUP_PAGE});
          dispatch({type: UPDATED});

          return;
        }

        dispatch({
          payload: {
            [type]: {
              ...data?.entity,
              ...(type === 'nuban' && {account_number}),
              caller_name: `${user?.first_name} ${user?.last_name}`,
              caller_email: user?.email,
              date_created: `${new Date()}`,
            },
            type,
          },
          type: LOOKUP,
        });
        dispatch({payload: appId, type: LOOKUP_APP_ID});
        dispatch({type: LOOKUP_PAGE});
        dispatch({type: UPDATED});
      },
    },
    {error: 'ERROR', loading: L_LOADING, responder: LOOKUP},
  );

export const changeLookupPage = value => dispatch =>
  dispatch({payload: value, type: LOOKUP_PAGE});
export const clearLookupDetails = () => dispatch =>
  dispatch({payload: null, type: LOOKUP});

export const performBatchLookup = (data, formData, appId, callback) =>
  reduxApi(
    `/users/widget/lookup/batch/${data}`,
    'POST',
    {...formData},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data.entity, type: BATCH_LOOKUP});
        dispatch({payload: appId, type: LOOKUP_APP_ID});
        toast.success('Bulk upload successful');
        callback(false);
        // history.push(PAGES_URL.IDENTITY_UPLOAD);
      },
    },
    {error: 'ERROR', loading: LOADING, responder: LOOKUP},
  );

export const getBatchLookup = ({
  company_id,
  page,
  limit,
  status,
  model_type,
  country,
  start,
  end,
  apps,
  download,
  caller_name,
  caller_email,
}) => {
  const filteredModelTypes = INDIVIDUALS_MODEL_TYPES.filter(
    type => type !== 'basic_bvn' && type !== 'passport',
  );

  filteredModelTypes.push('bvn_advance');

  const modelTypeParam = model_type && `&model_type=${model_type}`;
  // const modelTypeParam = `&model_type=${
  //   model_type ? model_type : filteredModelTypes.join('&model_type=')
  // }`;

  const apiParams = `company_id=${company_id}${page ? `&page=${page}` : ''}${
    limit ? `&limit=${limit}` : ''
  }${status ? `&status=${status}` : ''}${modelTypeParam ? modelTypeParam : ''}${
    country ? `&country=${country}` : ''
  }${start ? `&start=${start}` : ''}${end ? `&end=${end}` : ''}${
    apps ? `&app_id=${apps}` : ''
  }${download ? `&download=${download}` : ''}${
    caller_email ? `&caller_email=${caller_email}` : ''
  }${caller_name ? `&caller_name=${caller_name}` : ''}`;

  return reduxApi(
    `/users/widget/lookup/batch?${apiParams}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        if (download) {
          toast.success(data.message);
          dispatch({payload: 'lookupData', type: LOADING});
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          dispatch({payload: '', type: LOADING});

          const noFilter =
            !status && !model_type && !country && !apps && !start && !end;

          const lookupData =
            !noFilter && data.totalRecords === 0 ? undefined : data;
          dispatch({payload: lookupData, type: BATCH_LOOKUP});
        }
      },
    },
    {error: 'ERROR', loading: LOADING, responder: BATCH_LOOKUP},
  );
};

export const getBusinessBatchLookup = ({
  company_id,
  page,
  limit,
  status,
  model_type,
  country,
  start,
  end,
  apps,
  download,
  caller_name,
  caller_email,
}) =>
  reduxApi(
    `/users/widget/lookup/batch?model_type=cac&company_id=${company_id}${
      page ? `&page=${page}` : ''
    }${limit ? `&limit=${limit}` : ''}${status ? `&status=${status}` : ''}${
      model_type ? `&model_type=${model_type}` : ''
    }${country ? `&country=${country}` : ''}${start ? `&start=${start}` : ''}${
      end ? `&end=${end}` : ''
    }${apps ? `&app_id=${apps}` : ''}${
      download ? `&download=${download}` : ''
    }${caller_email ? `&caller_email=${caller_email}` : ''}${
      caller_name ? `&caller_name=${caller_name}` : ''
    }`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        if (download) {
          toast.success(data.message);
          dispatch({payload: 'lookupData', type: LOADING});
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          dispatch({payload: '', type: LOADING});

          const noFilter =
            !status && !model_type && !country && !apps && !start && !end;

          const lookupData =
            !noFilter && data.totalRecords === 0 ? undefined : data;
          dispatch({payload: lookupData, type: BATCH_BUSINESS_LOOKUP});
        }
      },
    },
    {error: 'ERROR', loading: LOADING, responder: BATCH_BUSINESS_LOOKUP},
  );
export const getBatch = id =>
  reduxApi(
    `/users/widget/lookup/batch/${id}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: SINGLE_BATCH});
      },
    },
    {error: 'ERROR', loading: L_LOADING, responder: SINGLE_BATCH},
  );
export const downloadBatchLookup = (id, callback) =>
  reduxApi(
    `/users/widget/lookup/batch/download/${id}`,
    'GET',
    null,
    {
      report: true,
      error: err => console.error(err),
      success: data => {
        window.open(data?.url, '_blank');
        window.location.reload();
        callback();
      },
    },
    {error: 'ERROR', loading: L_LOADING, responder: DOWNLOADING},
  );

export const getBankCodes = () =>
  reduxApi(
    '/users/widget/lookup/bank-codes',
    'GET',
    null,
    {},
    {error: 'ERROR', loading: LOADING, responder: BANK_CODES},
  );
