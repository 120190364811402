// Authentication routes

const SIGNIN = '/signin';
// const SIGNUP = '/signup';
const CREATE_COMPANY = '/signup/add-company';
const CUSTOM_LISTS = '/custom-lists';
const PROJECT_OVERVIEW = '/reconciliation/projects/overview/:slug';
const PROJECT_ACCOUNTS = '/reconciliation/projects/accounts/:slug';
const PROJECT_ACCOUNTS_DETAILS =
  '/reconciliation/projects/accounts/details/:slug';
const PROJECT_TRANSACTIONS = '/reconciliation/projects/transactions/:slug';
const PROJECT_RECONCILIATION = '/reconciliation/projects/reconciliation/:slug';
const PROJECT_CUSTOMERS = '/reconciliation/projects/customers/:slug';
const PROJECT_SETTINGS = '/reconciliation/projects/settings/:slug';
const RECONCILIATION_OVERVIEW = '/reconciliation/overview/:slug';
const RECONCILIATION_PROJECTS = '/reconciliation/projects';
const RECONCILIATION_TRANSACTIONS = '/reconciliation/transactions';
const RECONCILIATION = '/reconciliation/view';
const RECONCILIATION_SIGNUP = '/signup';
const RECONCILIATION_SIGNIN = '/signin';
const RECONCILIATION_FORGOT_PASSWORD = '/reconciliation/forgot-password';
const RECONCILIATION_SET_PASSWORD = '/reconciliation/set-password';
const RECONCILIATION_RESET_PASSWORD = '/reconciliation/reset-password';
const RECONCILIATION_REDIRECT = '/reconciliation/redirect';
const RECONCILIATION_GOOGLE_SIGN_UP = '/complete/sign-up';
const RECONCILIATION_CUSTOMERS = '/reconciliation/customers';
const RECONCILIATION_CUSTOMER_DETAILS =
  '/reconciliation/customers/details/:slug';
const CUSTOM_LISTS_ITEMS = '/custom-lists/:slug';
const ACCOUNT_NOT_FOUND = '/account-not-found';
const TEAM = '/join-company';
const CREATE_PASSWORD = '/create-password';
const FORGOT_PASSWORD = '/forgot-password';
const RESET_PASSWORD = '/reset-password/:token';
const DASHBOARD = '/dashboard';
const QUICKACTIONS = '/quick-actions';
const QUICKACTIONS_EXISTING = '/quick-actions/existing';
const AML_SCREENING = '/aml-screening';
const AML_SCREENING_RESULT = '/aml-screening/result/:slug';
const INDIVIDUAL_LOOKUP = '/easy-lookup/individual';
const INDIVIDUAL_LOOKUP_SINGLE = '/easy-lookup/individual/single';
const INDIVIDUAL_LOOKUP_BATCH = '/easy-lookup/individual/batch';
const BUSINESS_LOOKUP = '/easy-lookup/business';
const BUSINESS_LOOKUP_SINGLE = '/easy-lookup/business/single';
const BUSINESS_LOOKUP_BATCH = '/easy-lookup/business/batch';
const EASY_CONNECT = '/easy-connect';
const EASY_ONBOARD = '/easy-onboard';
const EASY_ONBOARD_CREATE_FLOW = '/easy-onboard/create-flow';
const EASY_ONBOARD_CUSTOM_FLOW = '/easy-onboard/custom-flow';
const EASY_ONBOARD_UPDATE_FLOW = '/easy-onboard/update-flow';
const OVERVIEW = '/overview';
const OVERVIEW_PENDING_CASES = '/pending-cases';
const FLOWS = '/flows';
const FLOWS_ANALYTICS = '/flows/analysis/:slug';
const FLOWS_EVENTS = '/flows/events/:slug';
const FLOWS_CASES = '/flows/cases/:slug';
const FLOWS_ACCOUNT = '/flows/account/:slug';
const FLOWS_ACCOUNT_TRANSACTIONS = '/flows/account/transactions/:slug';
const FLOWS_CASES_DETAILS = '/flows/cases/:id/details/:slug';
const FLOWS_CASES_BEHAVIOURAL_ANALYSIS =
  '/flows/cases/:id/behavioural-analysis/:slug';
const FLOWS_CASES_SUPPORTING_DOC = '/flows/cases/:id/supporting-doc/:slug';
const PENDING_CASES_DETAILS = '/pending-cases/details/:slug';
const FLOWS_SETTINGS = '/flows/settings/:slug';
const FLOWS_EDIT_PROCESS = '/flows/edit-process/:slug';
const VERIFICATIONS = '/verifications';
const VERIFICATION_ANALYTICS = '/verification-analytics';
const PENDING_VERIFICATIONS = '/verifications/pending';
const VERIFICATIONS_BIO_DATA = '/verifications/bio-data/:slug';
const VERIFICATIONS_ADDRESS = '/verifications/address/:slug';
const VERIFICATIONS_AML_CHECK = '/verifications/aml-check/:slug';
const VERIFICATIONS_IP_DEVICE_CHECK = '/verifications/ip-device-check/:slug';
const LINK_ACCOUNTS = '/link-accounts';
const DEVELOPERS_API_LOGS = '/developers/api-logs';
const DEVELOPERS_WEBHOOK = '/developers/webhooks';
const DEVELOPERS_WEBHOOK_DETAILS = '/developers/webhooks/:id';
const DEVELOPERS_CONFIGURATION = '/developers/configuration';
const SETTINGS_PROFILE = '/settings/profile';
const SETTINGS_COMPLIANCE = '/settings/compliance';
const SETTINGS_TEAM = '/settings/team';
const SETTINGS_AUDIT_LOGS = '/settings/audit-logs';
const SETTINGS_BILLING = '/settings/billing';
const SETTINGS_BILLING_PRICING = '/settings/billing/pricing';
const SETTINGS_TEAM_LAYOUT = '/settings/team/:slug';
const SETTINGS_TEAM_OWNER = '/settings/team/owner';
const ADDRESS_VERIFICATION = '/address-verification';
const BATCH_ADDRESS_VERIFICATION = '/address-verification/batch';
const API_TOKENS = '/api-tokens';
const GLOBAL_SEARCH_RESULTS =
  '/easy-lookup/business/global-search/result/:slug';
const EASY_CREDIT_CHECK = '/easy-creditcheck';
const EASY_CREDIT_CREDIT_SUMMARY = '/easy-creditcheck/credit-summary/:slug';
const EASY_CREDIT_BEHAVIOURAL_ANALYSIS =
  '/easy-creditcheck/behavioural-analysis/:slug';
const EASY_CREDIT_AFFORDABILITY = '/easy-creditcheck/affordability/:slug';
const EASY_CREDIT_KYC_INFORMATION = '/easy-creditcheck/kyc-information/:slug';
const EASY_CREDIT_AML_SCREENING = '/easy-creditcheck/aml-screening/:slug';
const API_REPORT = '/report/api';
const EASY_DETECT_REPORT = '/report/easy-detect';
const AML_USER_SCREENING = '/aml-screening/user';
const AML_USER_SCREENING_SEARCH_RESULTS =
  '/aml-screening/user/search-results/:slug';
const AML_BUSINESS_SCREENING_SEARCH_RESULTS =
  '/aml-screening/business/search-results/:slug';
const AML_USER_SCREENING_SINGLE = '/aml-screening/user/single';
const AML_USER_SCREENING_BATCH = '/aml-screening/user/batch';
const AML_BUSINESS_SCREENING = '/aml-screening/business';
const CUSTOMER_360 = '/customer-360';
const CUSTOMER_360_GOVT_LOOKUP = '/customer-360/government-lookup/:slug';
const CUSTOMER_360_GOVT_ID = '/customer-360/government-id/:slug';
const CUSTOMER_360_VALIDATION_INFO = '/customer-360/validation-info/:slug';
const CUSTOMER_360_AML_CHECK = '/customer-360/aml-check/:slug';
const CUSTOMER_360_ADDRESS_INFO = '/customer-360/address-info/:slug';
const CUSTOMER_360_IP_DEVICE_INFO = '/customer-360/ip-device-info/:slug';
const CUSTOMER_360_LIVENESS_CHECK = '/customer-360/liveness-check/:slug';
const DOCUMENT_ANALYSIS = '/document-analysis';
const DOCUMENT_ANALYSIS_SINGLE = '/document-analysis/single';
const DOCUMENT_ANALYSIS_BATCH = '/document-analysis/batch';
const MESSAGING_REPORT = '/report/messaging';
const AML_BUSINESS_SCREENING_SINGLE = '/aml-screening/business/single';
const AML_BUSINESS_SCREENING_BATCH = '/aml-screening/business/batch';
const BUSINESS_360 = '/business-360';
const BUSINESS_360_BUSINESS_LOOKUP = '/business-360/business-lookup/:slug';
const BUSINESS_360_BUSINESS_ID = '/business-360/business-id/:slug';
const BUSINESS_360_AML_SEARCH = '/business-360/aml-search/:slug';
const BUSINESS_360_DIRECTOR_INFO = '/business-360/director-info/:slug';
const BAAS_OVERVIEW = '/baas/overview';
const BAAS_TRANSACTIONS = '/baas/transactions';
const BAAS_WALLETS = '/baas/wallets';
const BAAS_PAYOUTS = '/baas/payouts';
const BAAS_SETTINGS = '/baas/settings';
const BAAS_ACCOUNT_DETAIL = '/baas/wallet-detail/:slug';
const RECONCILIATION_BILLING = '/reconciliation/billing';
const RECONCILIATION_BILLING_HISTORY = '/reconciliation/payment-history';
const SETTINGS_COMPANY_VERIFICATION = '/settings/company-verification';
const AUDIT_LOGS = '/logs/audit';
const EXPORT_LOGS = '/logs/export';

const PAGES_URL = {
  TEAM,
  FLOWS,
  SIGNIN,
  CUSTOM_LISTS,
  EXPORT_LOGS,
  AUDIT_LOGS,
  CUSTOM_LISTS_ITEMS,
  OVERVIEW,
  DASHBOARD,
  INDIVIDUAL_LOOKUP,
  FLOWS_CASES,
  EASY_CONNECT,
  QUICKACTIONS,
  PROJECT_OVERVIEW,
  PROJECT_ACCOUNTS,
  PROJECT_ACCOUNTS_DETAILS,
  RECONCILIATION_PROJECTS,
  RECONCILIATION_OVERVIEW,
  RECONCILIATION_TRANSACTIONS,
  RECONCILIATION_CUSTOMERS,
  RECONCILIATION,
  RECONCILIATION_SIGNUP,
  RECONCILIATION_SIGNIN,
  RECONCILIATION_FORGOT_PASSWORD,
  RECONCILIATION_REDIRECT,
  RECONCILIATION_SET_PASSWORD,
  RECONCILIATION_RESET_PASSWORD,
  RECONCILIATION_GOOGLE_SIGN_UP,
  PROJECT_TRANSACTIONS,
  PROJECT_RECONCILIATION,
  PROJECT_CUSTOMERS,
  RECONCILIATION_CUSTOMER_DETAILS,
  PROJECT_SETTINGS,
  EASY_ONBOARD,
  FLOWS_EVENTS,
  AML_SCREENING,
  VERIFICATIONS,
  LINK_ACCOUNTS,
  SETTINGS_TEAM,
  CREATE_COMPANY,
  RESET_PASSWORD,
  FLOWS_SETTINGS,
  CREATE_PASSWORD,
  FORGOT_PASSWORD,
  FLOWS_ANALYTICS,
  SETTINGS_BILLING,
  SETTINGS_PROFILE,
  ACCOUNT_NOT_FOUND,
  FLOWS_EDIT_PROCESS,
  DEVELOPERS_WEBHOOK,
  FLOWS_CASES_DETAILS,
  DEVELOPERS_API_LOGS,
  SETTINGS_TEAM_OWNER,
  SETTINGS_COMPLIANCE,
  SETTINGS_AUDIT_LOGS,
  AML_SCREENING_RESULT,
  SETTINGS_TEAM_LAYOUT,
  VERIFICATION_ANALYTICS,
  VERIFICATIONS_BIO_DATA,
  VERIFICATIONS_ADDRESS,
  QUICKACTIONS_EXISTING,
  VERIFICATIONS_AML_CHECK,
  SETTINGS_COMPANY_VERIFICATION,
  PENDING_VERIFICATIONS,
  PENDING_CASES_DETAILS,
  OVERVIEW_PENDING_CASES,
  EASY_ONBOARD_CREATE_FLOW,
  EASY_ONBOARD_CUSTOM_FLOW,
  EASY_ONBOARD_UPDATE_FLOW,
  DEVELOPERS_CONFIGURATION,
  SETTINGS_BILLING_PRICING,
  DEVELOPERS_WEBHOOK_DETAILS,
  VERIFICATIONS_IP_DEVICE_CHECK,
  FLOWS_CASES_BEHAVIOURAL_ANALYSIS,
  FLOWS_CASES_SUPPORTING_DOC,
  // SIGNUP,
  FLOWS_ACCOUNT,
  FLOWS_ACCOUNT_TRANSACTIONS,
  ADDRESS_VERIFICATION,
  BATCH_ADDRESS_VERIFICATION,
  BUSINESS_LOOKUP,
  INDIVIDUAL_LOOKUP_SINGLE,
  INDIVIDUAL_LOOKUP_BATCH,
  BUSINESS_LOOKUP_SINGLE,
  BUSINESS_LOOKUP_BATCH,
  API_TOKENS,
  GLOBAL_SEARCH_RESULTS,
  EASY_CREDIT_CHECK,
  EASY_CREDIT_CREDIT_SUMMARY,
  EASY_CREDIT_BEHAVIOURAL_ANALYSIS,
  EASY_CREDIT_AFFORDABILITY,
  EASY_CREDIT_KYC_INFORMATION,
  EASY_CREDIT_AML_SCREENING,
  API_REPORT,
  EASY_DETECT_REPORT,
  AML_USER_SCREENING,
  AML_BUSINESS_SCREENING,
  AML_USER_SCREENING_SINGLE,
  AML_USER_SCREENING_BATCH,
  AML_USER_SCREENING_SEARCH_RESULTS,
  AML_BUSINESS_SCREENING_SEARCH_RESULTS,
  CUSTOMER_360,
  CUSTOMER_360_GOVT_LOOKUP,
  CUSTOMER_360_GOVT_ID,
  CUSTOMER_360_VALIDATION_INFO,
  CUSTOMER_360_AML_CHECK,
  CUSTOMER_360_ADDRESS_INFO,
  CUSTOMER_360_IP_DEVICE_INFO,
  CUSTOMER_360_LIVENESS_CHECK,
  DOCUMENT_ANALYSIS,
  DOCUMENT_ANALYSIS_SINGLE,
  DOCUMENT_ANALYSIS_BATCH,
  MESSAGING_REPORT,
  AML_BUSINESS_SCREENING_SINGLE,
  AML_BUSINESS_SCREENING_BATCH,
  BUSINESS_360,
  BUSINESS_360_BUSINESS_LOOKUP,
  BUSINESS_360_BUSINESS_ID,
  BUSINESS_360_AML_SEARCH,
  BUSINESS_360_DIRECTOR_INFO,
  BUSINESS_360_GOVT_LOOKUP: undefined,
  BAAS_OVERVIEW,
  BAAS_TRANSACTIONS,
  BAAS_WALLETS,
  BAAS_PAYOUTS,
  BAAS_SETTINGS,
  BAAS_ACCOUNT_DETAIL,
  RECONCILIATION_BILLING,
  RECONCILIATION_BILLING_HISTORY,
};

export {PAGES_URL};
