import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {notFound} from '../assets/images/images';
import {
  DashboardLayout,
  PrimaryButton,
  SecondaryButton,
  Sidebar,
} from '../components';

export default function NotFound({error, resetErrorBoundary}) {
  useEffect(() => {
    if (error) {
      sessionStorage.setItem('resetErrorBoundary', true);
    }
  }, [error, resetErrorBoundary]);
  return (
    <>
      <Sidebar />
      <DashboardLayout topPadding="0" xlLeftMargin="0" xlRightPadding="0">
        <div
          className="flex flex-col items-center justify-center gap-4 m-auto text-center"
          style={{
            height: 'calc(100vh - 224px)',
          }}
        >
          <img src={notFound} alt="" width={144} height={144} />
          {!error && (
            <h3 className="text-xxl text-grey">Aish! Something is broken.</h3>
          )}
          {error && (
            <>
              <p className="text-grey text-lg">Opps! Something is broken.</p>
              <h3 className="text-xl text-red-800">{error?.message} 🌍</h3>
            </>
          )}

          <div className="flex items-center gap-4">
            <PrimaryButton
              buttonText="Go back to Quick Actions"
              onClick={() => (location.href = '/quick-actions')}
            />

            {resetErrorBoundary ? (
              <SecondaryButton
                buttonText="Reload Page"
                onClick={resetErrorBoundary}
              />
            ) : (
              <Link to="/">
                <SecondaryButton buttonText="Contact support" />
              </Link>
            )}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}
