import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import * as MainAssets from '../../../assets/images/images';
import * as Components from '../../../components';
import CreateFlowModal from './CreateFlowModal';
import * as Actions from '../../../store/actions';
import {useFlowsLogic} from './hooks/useFlows';
import {getStatusTextAndStyles} from '../../../helpers/getStatusTextAndStyles';

function FraudDetectionFlows() {
  const navigate = useNavigate();
  const [openFlowModal, setOpenFlowModal] = useState(false);
  const {
    currentPage,
    pageClick,
    manageFlows,
    flows,
    TOTAL_EVENTS,
    TOTAL_BLOCKED,
    TOTAL_ALLOWED,
    TOTAL_PENDING,
    isLoading,
    setLimit,
    companyId,
    overviewLoading,
    easyFlows,
    limit,
  } = useFlowsLogic();

  return (
    <Components.DashboardLayout
      bg="bg-white"
      topPadding="pt-6"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={MainAssets.fraudFlowBreadcrumb} alt="" />
          <p className="ml-2 -mb-1">Fraud Flows</p>
        </div>
      }
    >
      <CreateFlowModal
        open={openFlowModal}
        setOpen={setOpenFlowModal}
        companyId={companyId}
      />

      <section className="flex flex-col">
        <div className="flex  justify-between flex-col w-full mb-6">
          <div className="flex justify-between items-center w-full">
            <h1 className="text-grey text-xl font-medium">Overview</h1>
            <div className="flex items-center gap-4">
              {/*<SearchInput />*/}
              <Components.PrimaryButton
                buttonText="Create a flow"
                onClick={() => setOpenFlowModal(true)}
                disabled={!manageFlows}
              />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row items-center gap-4 my-6">
            <Components.Card
              className="w-full"
              img={MainAssets?.totalEvents}
              title="Total Events"
              body={TOTAL_EVENTS?.count || 0}
              loading={overviewLoading}
              count={TOTAL_EVENTS?.count}
            />
            <Components.Card
              className="w-full"
              img={MainAssets?.pendingEvents}
              title="Pending Cases"
              body={TOTAL_PENDING?.count || 0}
              loading={overviewLoading}
              count={TOTAL_PENDING?.count}
            />
            <Components.Card
              className="w-full"
              img={MainAssets?.allowedEvents}
              title="Allowed Cases"
              body={TOTAL_ALLOWED?.count || 0}
              loading={overviewLoading}
              count={TOTAL_ALLOWED?.count}
            />
            <Components.Card
              className="w-full"
              img={MainAssets?.blockedEvents}
              title="Blocked Cases"
              body={TOTAL_BLOCKED?.count || 0}
              loading={overviewLoading}
              count={TOTAL_BLOCKED?.count}
            />
          </div>
        </div>

        <div className="flex items-center justify-between my-4">
          <h2 className="text-grey text-base sm:text-lg font-medium">Flows</h2>
        </div>

        <Components.TableLayout
          negativeMargins
          negativeRightMargin="-mr-4 xl:-mr-[76px]"
        >
          <thead className="text-xs font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>Name of flow</span>
                </div>
              </th>

              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>USe cases</span>
                </div>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>date created</span>
                </div>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>STATUS</span>
                </div>
              </th>
              <th className="min-w-[175px]"></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colSpan={8}>
                {isLoading ? (
                  <Components.Loader height={45} />
                ) : !isLoading && flows && flows?.flows?.length === 0 ? (
                  <Components.EmptyState
                    height={45}
                    buttonText="Create a flow"
                    onClick={() => setOpenFlowModal(true)}
                    heading="No record found!"
                    body="Create a flow with ease"
                  />
                ) : null}
              </td>
            </tr>
          </tbody>

          <tbody className="text-sm bg-white">
            {flows &&
              flows?.flows?.map(flow => (
                <tr
                  className="border-b border-grey60  "
                  key={flow.id}
                  style={{
                    pointerEvents: manageFlows ? 'auto' : 'none',
                  }}
                >
                  <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                    {flow?.name || '-'}
                  </td>

                  <td className="capitalize p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {flow?.usecase || '-'}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {moment(flow.createdAt).format('Do MMM YYYY hh:mm')}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    <div
                      className={`uppercase py-1 px-2 text-xs font-medium ${
                        getStatusTextAndStyles(flow?.status).solid
                      }  rounded-full flex items-center w-fit`}
                    >
                      <span className="-mb-[2px]">{flow?.status || '-'}</span>
                    </div>
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap relative">
                    <div className="flex items-center gap-10 z-2 p-5 absolute top-0">
                      {/*<SettingsToggle />*/}
                      <span
                        title="View"
                        className="h-4 cursor-pointer aspect-square"
                        onClick={() =>
                          navigate(`/flows/analysis/${flow.id}`, {
                            state: {
                              flowName: flow?.name || '-',
                              flowData: flow,
                            },
                          })
                        }
                      >
                        <img src={MainAssets?.eye} alt="" />
                      </span>

                      {/*<span*/}
                      {/*  title="Edit process"*/}
                      {/*  className="h-4 cursor-pointer aspect-square"*/}
                      {/*  style={{*/}
                      {/*    pointerEvents:*/}
                      {/*      manageFlows && editProcess ? 'auto' : 'none',*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  <img src={MainAssets.edit} alt="" />*/}
                      {/*</span>*/}
                      {/* <p onClick={() => deleteFlow(flow._id)}>delete</p> */}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Components.TableLayout>

        {flows && flows?.flows?.length !== 0 && (
          <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
            <div className="flex items-center text-body text-tiny">
              <Components.PageLimit
                onLimitChange={setLimit}
                length={flows && flows?.flows?.length}
                limit={limit}
              />
            </div>

            <div className="mb-8 sm:mb-0">
              <Components.Pagination
                total={easyFlows && Math.ceil(easyFlows?.pages / limit)}
                current={+currentPage}
                onPageChange={activePage => {
                  pageClick(activePage);
                  const searchParams = new URLSearchParams(
                    document.location.search,
                  );
                  searchParams.set('page', activePage);
                  const newSearchParams = searchParams.toString();
                  navigate(`?${newSearchParams}`);
                }}
              />
            </div>
          </div>
        )}
      </section>
    </Components.DashboardLayout>
  );
}

export default connect(state => state, Actions)(FraudDetectionFlows);
