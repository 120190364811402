import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {fetchLookupHistoryAnalysis} from '../requests/queries/lookup';
import {useAllApps} from './useApps';
import {useSelector} from 'react-redux';

export const useLookupHistoryAnalysis = ({IDENTITY_TYPES = []}) => {
  const {userApps, isLoading: appsLoading} = useAllApps();
  const {userDetails} = useSelector(state => state?.auth);

  const params = useMemo(
    () =>
      !appsLoading &&
      userApps && {
        app_ids: userApps.map(app => app?.value)?.join(','),
        company_id: userDetails?.company?.id,
        entity_types: IDENTITY_TYPES?.join(','),
      },
    [appsLoading, userApps, userDetails?.company?.id, IDENTITY_TYPES],
  );

  const {
    data: analysis_data,
    isLoading: analysisLoading,
    refetch,
  } = useQuery(
    ['lookup-history-analysis', {params}],
    () => fetchLookupHistoryAnalysis({params}),
    {
      enabled: userApps?.length > 0,
    },
  );

  const analysisData = useMemo(
    () => !analysisLoading && analysis_data && analysis_data?.entity,
    [analysisLoading, analysis_data],
  );
  return {analysisData, analysisLoading, refetch};
};
