/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-indent */
import React, {useEffect, useMemo, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import * as Actions from '../../../store/actions';
import EasyLookupModals from '../EasyLookupModals';
import {
  DashboardLayout,
  Filter,
  FilterButton,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  TableLayout,
} from '../../../components';
import {
  backNofill,
  corruptedFile,
  easyLookupIc,
  eye,
  failed,
} from '../../../assets/images/images';
import {
  capitalizeEveryWord,
  checkStatus,
  Constants,
  filterOutEmptyValues,
  generateQueryParams,
  mapToOptionsArray,
  parseUrlParams,
} from '../../../helpers';
import {getCookieValue} from '../../../helpers/getCookie';
import {PAGES_URL} from '../../../helpers/constants';
import {
  COUNTRIES,
  defaultFilterOptions,
  getIDType,
  tableHeader,
} from '../LookupUtils';
import {EmptyFilterState} from '../../../components/EmptyFilterState';
import {OverviewCards} from '../../../components/OverviewCards';
import {buildTableCardData} from '../../../helpers/buildTableCardData';
import {useAllApps} from '../../../hooks/useApps';

function IndividualBatchLookup({
  changeLookupPage,
  getBatchLookup,
  getApiLogs,
  getBatch,
  lookup: {batchLookup, loading, h_loading},
  auth: {userDetails},
  apiLogs: {logs, loading: apiLoading},
}) {
  const navigate = useNavigate();
  const {userApps: allAppsData} = useAllApps();

  const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [openBatchLookup, setOpenBL] = useState(false);

  const env = getCookieValue('env');
  const storedCountries = JSON.parse(localStorage.getItem('selectedCountry'));
  const finalValue = Constants.lookups.getMultipleIDType(
    storedCountries || ['nigeria'],
  );

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const SANDBOX_DATA =
    useMemo(
      () =>
        !apiLoading &&
        logs &&
        logs.api_logs?.map((log, index) => ({
          name: 'Phone-number-lookup.csv',
          model_type: 'phone_number',
          model_type_id: index,
          status: 'complete',
          date_created: log?.createdAt,
        })),
      [logs && logs.api_logs.length],
    ) || [];

  useEffect(() => {
    if (env === 'Sandbox') {
      getApiLogs({page: currentPage, limit});
    } else {
      const apiParams = parseUrlParams();
      apiParams.limit = limit;
      apiParams.company_id = userDetails?.company?.id;

      const searchParams = new URLSearchParams(document.location.search);
      const pageParam = searchParams.get('page');
      if (pageParam) {
        setCurrentPage(parseInt(pageParam));
        apiParams.page = parseInt(pageParam);
      } else {
        apiParams.page = currentPage;
      }

      const appIdsFromUrl = searchParams.get('app_id');
      if (appIdsFromUrl) {
        const appIdsArray = appIdsFromUrl.split(',');
        apiParams.apps = appIdsArray?.map(app => app).join('&app_id=');
      } else if (allAppsData?.length > 0) {
        apiParams.apps = allAppsData?.map(app => app.value).join('&app_id=');
      }

      allAppsData?.length > 0 && currentPage && getBatchLookup(apiParams);

      if (
        Object.keys(apiParams).length === 5 &&
        'page' in apiParams &&
        'apps' in apiParams &&
        'limit' in apiParams &&
        'company_id' in apiParams &&
        'model_type' in apiParams
      ) {
        setFiltersApplied(true);
      } else {
        setFiltersApplied(false);
      }
    }
  }, [
    allAppsData?.length,
    currentPage,
    env,
    getApiLogs,
    getBatchLookup,
    limit,
    userDetails?.company?.id,
  ]);

  useEffect(() => {
    if (env === 'Sandbox' && logs && !apiLoading) {
      setData(SANDBOX_DATA);
    } else {
      !loading && batchLookup && setData([...(batchLookup?.records || [])]);
    }
  }, [env, batchLookup, apiLoading, SANDBOX_DATA.length]);

  const filterOptions = useMemo(() => {
    if (!allAppsData) {
      return defaultFilterOptions;
    }

    const modelTypeFilters = (finalValue?.flat() || []).map(filter => ({
      ...filter,
      value:
        filter?.value === 'dl'
          ? 'driving_license'
          : filter?.value === 'basic'
          ? 'basic_phone'
          : filter?.value === 'advance'
          ? 'bvn_advance'
          : filter?.value,
      label: `${Constants.lookups.getCountryAcronym(
        filter?.name?.replace('_id', ''),
      )} ${
        filter?.value === 'account_number' ? 'Account Number' : filter?.label
      }`,
    }));

    const uniqueModelTypeFilters = modelTypeFilters.reduce(
      (uniqueFilters, filter) => {
        if (
          !uniqueFilters.some(
            uniqueFilter => uniqueFilter.value === filter.value,
          )
        ) {
          uniqueFilters.push(filter);
        }
        return uniqueFilters;
      },
      [],
    );

    return [
      {
        title: 'Apps',
        name: 'apps',
        values: mapToOptionsArray(allAppsData),
      },
      {
        title: 'Model type',
        name: 'model_type',
        values: uniqueModelTypeFilters.filter(option => option.value !== 'cac'),
      },
      {
        title: 'Status',
        name: 'status',
        values: Constants.lookups.status,
      },
      {
        title: 'Country',
        name: 'country',
        values: COUNTRIES,
      },
    ];
  }, [allAppsData, finalValue]);

  const handleFilter = (selectedOptions, actionType) => {
    const {apps, status, model_type, country, ...filteredOptions} =
      filterOutEmptyValues(selectedOptions);
    const appIds =
      filteredOptions.app_id || allAppsData?.map(app => app?.value).join(',');

    const appsForApiCall = apps
      ? apps
          .map((app, index) => (index === 0 ? app : `app_id=${app}`))
          .join('&')
      : '';

    const formattedArrayParam = (array, paramName) =>
      array
        ? array.map((value, index) =>
            index === 0 ? value : `${paramName}=${value}`,
          )
        : [];

    const formattedAppIds = appIds
      ? appIds.split(',').map(appId => `app_id=${appId}`)
      : [];
    const formattedStatus = formattedArrayParam(status, 'status');
    const formattedType = formattedArrayParam(model_type, 'model_type');
    const formattedCountry = formattedArrayParam(country, 'country');

    const modifiedStatus = status ? status.join(',') : '';
    const modifiedType = model_type ? model_type.join(',') : '';
    const modifiedCountry = country ? country.join(',') : '';

    const appIdArray = appsForApiCall.split('&');

    const appIdsUrl = appIdArray
      .filter(appId => appId !== 'app_id=')
      .map(appId => appId.replace('app_id=', ''));

    const formattedAppIdsUrl = `app_id=${appIdsUrl.join(',')}`;
    const queryParamsArray = [
      appsForApiCall && formattedAppIdsUrl,
      modifiedStatus && `status=${modifiedStatus}`,
      modifiedType && `model_type=${modifiedType}`,
      modifiedCountry && `country=${modifiedCountry}`,
      actionType === 'download' ? '' : `page=1`,
    ];

    const customDateParams = generateQueryParams(filteredOptions);
    if (customDateParams && actionType !== 'download') {
      queryParamsArray.push(customDateParams);
    }

    const queryParams = queryParamsArray.filter(Boolean).join('&');

    setCurrentPage(1);
    navigate(`?${queryParams}`);

    const apiParams = {
      company_id: userDetails?.company?.id,
      page: 1,
      limit,
      model_type: formattedType.join('&'),
      apps: formattedAppIds.join('&'),
      status: formattedStatus.join('&'),
      country: formattedCountry.join('&'),
      ...filteredOptions,
    };

    if (actionType === 'download') {
      apiParams.download = true;
    }

    if (appsForApiCall) {
      apiParams.apps = appsForApiCall;
    } else if (appIds) {
      if (formattedAppIds?.length > 0) {
        formattedAppIds[0] = formattedAppIds?.[0].replace('app_id=', '');
      }
      apiParams.apps = formattedAppIds.join('&');
    }

    getBatchLookup(apiParams);
    actionType !== 'download' && setFiltersApplied(true);

    setOpenFilter(false);
  };

  const isOwner = userDetails?.permissions?.role === 'Owner';

  const {performBatchIndividualLookup, downloadIndividualLookup} =
    useMemo(() => userDetails?.permissions?.lookupPermissions, [userDetails]) ||
    {};

  const isSearch = !h_loading && batchLookup === undefined;

  const handleResetFilter = () => {
    const app_ids = allAppsData?.map(app => app.value).join('&app_id=');

    const apiParams = {
      company_id: userDetails?.company?.id,
      apps: app_ids,
      page: 1,
    };

    navigate('');
    setCurrentPage(1);
    getBatchLookup(apiParams);
    setOpenFilter(true);
    setFiltersApplied(false);
  };

  const performRight = isOwner ? false : !performBatchIndividualLookup;

  const tableCardData = buildTableCardData({count: batchLookup?.totalRecords});

  const renderEmptyState = () =>
    h_loading || loading || (env === 'Sandbox' && apiLoading) ? (
      <tbody>
        <tr>
          <td colSpan={8}>
            <Loader height={50} />
          </td>
        </tr>
      </tbody>
    ) : isSearch ? (
      !filtersApplied ? (
        <EmptyFilterState
          body="No historical record available"
          onClick={() => setOpenBL(!openBatchLookup)}
          src={corruptedFile}
          disabled={!userDetails?.company?.verified || performRight}
          style={{
            cursor:
              (!userDetails?.company?.verified || performRight) &&
              'not-allowed',
          }}
          buttonText="Perform batch lookup"
          imageWidth={48}
          imageHeight={48}
        />
      ) : (
        <EmptyFilterState
          body="This filter did not return any data, try using different values."
          noBtn
          src={corruptedFile}
          customBtn={
            <button
              onClick={handleResetFilter}
              className="text-brandBlue p-4 text-sm font-medium"
            >
              Update preferences
            </button>
          }
        />
      )
    ) : env === 'Sandbox' && !apiLoading && data.length === 0 ? (
      <EmptyFilterState
        body="No historical record available"
        onClick={() => setOpenBL(!openBatchLookup)}
        src={corruptedFile}
        disabled={!userDetails?.company?.verified || performRight}
        style={{
          cursor:
            (!userDetails?.company?.verified || performRight) && 'not-allowed',
        }}
        buttonText="Perform batch lookup"
        imageWidth={48}
        imageHeight={48}
      />
    ) : null;

  return (
    <DashboardLayout
      bg="bg-white80"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={easyLookupIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Easy lookup</p>
        </div>
      }
    >
      <EasyLookupModals
        open={openBatchLookup}
        setOpen={setOpenBL}
        lookupType="Batch"
        batch={false}
      />

      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={options => handleFilter(options, 'filter')}
        resetUrl={handleResetFilter}
        filterOptions={filterOptions}
      />

      <div className="flex items-center justify-between">
        <Link
          to={PAGES_URL.INDIVIDUAL_LOOKUP}
          className="flex items-center gap-2"
        >
          <img src={backNofill} alt="" />
          <span className="text-small text-grey80 font-medium -mb-1">Back</span>
        </Link>
      </div>

      <>
        <div className="flex pt-10 flex-col items-start justify-between gap-6 mt-4 mb-6 md:flex-row md:items-center md:gap-0 sm:mt-0">
          <h2 className="text-base font-medium text-grey sm:text-lg">
            Batch Lookup history
          </h2>

          <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
            {env !== 'Sandbox' && (
              <FilterButton
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
              />
            )}
            {downloadIndividualLookup && (
              <div className="flex flex-wrap items-center gap-4 ">
                <PrimaryButton
                  onClick={options => handleFilter(options, 'download')}
                  buttonText="Export"
                  className="w-[100px]"
                  disabled={!downloadIndividualLookup}
                  title={
                    !downloadIndividualLookup
                      ? 'You do not have permission to perform this action'
                      : ''
                  }
                />
              </div>
            )}
          </div>
        </div>

        <OverviewCards
          cardData={tableCardData}
          overviewLoading={h_loading}
          className={`grid  md:grid-cols-${
            tableCardData?.length > 3 ? tableCardData?.length - 1 : 3
          } xl:grid-cols-${
            tableCardData?.length
          } gap-2 lg:gap-4 flex-wrap my-5`}
        />

        <TableLayout negativeMargins>
          <thead className="text-xs font-semibold uppercase">
            <tr className="bg-white80">
              {tableHeader.map(({label, key}) => (
                <th
                  key={key}
                  className={`${
                    label === 'Name'
                      ? 'p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap'
                      : 'p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap'
                  }`}
                >
                  <div className="flex items-end gap-1 cursor-pointer">
                    <span>{label}</span>
                  </div>
                </th>
              ))}

              <th></th>
            </tr>
          </thead>

          {renderEmptyState()}

          <tbody className="text-sm bg-white">
            {!(h_loading || loading || (env === 'Sandbox' && apiLoading)) &&
              data &&
              data.map((record, i) => (
                <tr
                  onClick={() => {
                    setOpenBL(!openBatchLookup);
                    changeLookupPage(3);
                    env !== 'Sandbox' && getBatch(record?.id);
                  }}
                  className="border-b cursor-pointer border-grey60"
                  key={i}
                >
                  <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                    {` ${
                      record?.first_name?.toUpperCase() ||
                      record?.name?.replaceAll('_', ' ')?.toUpperCase() ||
                      '-'
                    } ${record?.last_name?.toUpperCase() || '-'}`}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {`${Constants.lookups.getCountryAcronym(
                      record?.country,
                    )} ${capitalizeEveryWord(
                      getIDType(record?.model_type || record?.lookup_type),
                    )}`}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    <div
                      className={`px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit ${
                        record?.status?.toLowerCase() === 'completed'
                          ? 'text-success20 bg-success'
                          : record?.status.toLowerCase() === 'failed'
                          ? 'text-white bg-status-404'
                          : record?.status.toLowerCase() === 'processing'
                          ? 'text-grey80 bg-grey4'
                          : 'text-white bg-brandBlue'
                      }`}
                    >
                      {checkStatus(record?.status) || '-'}
                    </div>
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {moment(record?.date_created).format(
                      'MMM DD, YYYY hh:mmA',
                    ) || '-'}
                  </td>
                  {record.status === 'complete' && (
                    <td className="p-4 pl-0 whitespace-nowrap">
                      <div className="flex items-center gap-4">
                        <button
                          title="View"
                          onClick={() => {
                            setOpenBL(!openBatchLookup);
                            changeLookupPage(3);
                            env !== 'Sandbox' && getBatch(record?.id);
                          }}
                          className="h-4 aspect-square"
                        >
                          <img src={eye} alt="" />
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </TableLayout>

        {!h_loading && data?.length !== 0 && (
          <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
            <div className="flex items-center text-body text-tiny">
              <PageLimit
                onLimitChange={setLimit}
                // className="mt-3 mt-sm-0"
                total={batchLookup && batchLookup?.totalRecords}
                length={batchLookup?.records?.length}
                limit={limit}
              />
            </div>

            <div className="mb-8 sm:mb-0">
              <Pagination
                total={
                  batchLookup && Math.ceil(batchLookup?.totalRecords / limit)
                }
                current={+currentPage}
                onPageChange={activePage => {
                  pageClick(activePage);
                  const searchParams = new URLSearchParams(
                    document.location.search,
                  );
                  searchParams.set('page', activePage);
                  const newSearchParams = searchParams?.toString();
                  navigate(`?${newSearchParams}`);
                }}
              />
            </div>
          </div>
        )}
      </>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(IndividualBatchLookup);
