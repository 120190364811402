/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-indent */
import React, {useEffect, useMemo, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import * as Actions from '../../../store/actions';
import EasyLookupModals from '../EasyLookupModals';
import {
  DashboardLayout,
  Filter,
  FilterButton,
  PageLimit,
  Pagination,
  PrimaryButton,
  TableLayout,
  EmptyState,
  Loader,
  SearchInput,
} from '../../../components';
import {
  backNofill,
  corruptedFile,
  easyLookupIc,
  eye,
  failed,
  greenCheckBg,
  lookupEmpty,
  successful,
} from '../../../assets/images/images';
import {
  checkStatus,
  Constants,
  errorCodeColor,
  filterOutEmptyValues,
  generateQueryParams,
  mapToOptionsArray,
  parseUrlParams,
} from '../../../helpers';
import {getCookieValue} from '../../../helpers/getCookie';
import {PAGES_URL} from '../../../helpers/constants';
import {
  COUNTRIES,
  defaultFilterOptions,
  singleIndividualLookupTableHeader,
} from '../LookupUtils';
import {useQuery} from 'react-query';
import {fetchApiLogsFilters} from '../../../requests/queries/dashboard';
import {OverviewCards} from '../../../components/OverviewCards';
import {shortenLargeNumbers} from '../../../helpers/shortenLargeNumbers';
import {buildTableCardData} from '../../../helpers/buildTableCardData';
import {useLookupHistoryAnalysis} from '../../../hooks/useLookupHistoryAnalysis';
import {useAllApps} from '../../../hooks/useApps';
import {INDIVIDUALS_MODEL_TYPES} from '../../../store/actions';

function IndividualSingleLookup({
  children,
  getLookupHistory,
  fetchLookup,
  changeLookupPage,
  getApiLogs,
  lookup: {history, h_loading, loading},
  auth: {userDetails},
  apiLogs: {logs, loading: apiLoading},
}) {
  const navigate = useNavigate();
  const {userApps: allAppsData} = useAllApps();

  const {analysisData, analysisLoading} = useLookupHistoryAnalysis({
    IDENTITY_TYPES: INDIVIDUALS_MODEL_TYPES,
  });

  const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [openIndividualLookup, setOpenIL] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const {data: filters} = useQuery(['filters'], fetchApiLogsFilters);

  const env = getCookieValue('env');
  const storedCountries = JSON.parse(localStorage.getItem('selectedCountry'));
  const finalValue = Constants.lookups.getMultipleIDType(
    storedCountries || ['nigeria'],
  );

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    if (env === 'Sandbox') {
      Object.values(userDetails?.permissions?.logPermissions || {}).some(
        value => value,
      ) && getApiLogs({page: currentPage, limit});
    } else if (!children) {
      const apiParams = parseUrlParams();
      apiParams.limit = limit;
      apiParams.company_id = userDetails?.company?.id;
      localStorage.setItem('company_id', userDetails?.company?.id);

      const searchParams = new URLSearchParams(document.location.search);
      const pageParam = searchParams.get('page');

      if (pageParam) {
        setCurrentPage(parseInt(pageParam));
        apiParams.page = parseInt(pageParam);
      } else {
        apiParams.page = currentPage;
      }

      const appIdsFromUrl = searchParams.get('app_id');
      if (appIdsFromUrl) {
        const appIdsArray = appIdsFromUrl.split(',');
        apiParams.apps = appIdsArray?.map(app => app).join('&app_id=');
      } else if (allAppsData?.length > 0) {
        apiParams.apps = allAppsData?.map(app => app?.value).join('&app_id=');
      }
      apiParams.search = searchTerm;
      allAppsData?.length > 0 && currentPage && getLookupHistory(apiParams);

      const isFiltered =
        Object.keys(apiParams).length > 0 &&
        'page' in apiParams &&
        'apps' in apiParams &&
        'limit' in apiParams &&
        'company_id' in apiParams &&
        'model_type' in apiParams &&
        'search' in apiParams;

      if (isFiltered) {
        setFiltersApplied(false);
      } else {
        setFiltersApplied(true);
      }
    }
  }, [
    allAppsData?.length,
    currentPage,
    env,
    getApiLogs,
    getLookupHistory,
    limit,
    userDetails?.company?.id,
    searchTerm,
  ]);

  const SANDBOX_DATA = useMemo(
    () =>
      !apiLoading &&
      logs &&
      logs.api_logs?.map((log, index) => ({
        first_name: 'John',
        last_name: 'Doe',
        model_type: 'phone_number',
        model_type_id: index,
        status: 'complete',
        date_created: log?.createdAt,
      })),
    [logs && logs.api_logs.length],
  ) || [logs];

  useEffect(() => {
    if (env === 'Sandbox' && !apiLoading && logs) {
      setData(SANDBOX_DATA);
    } else {
      !h_loading && history && setData([...(history?.rows || [])]);
    }
  }, [env, history, apiLoading, SANDBOX_DATA.length]);

  const filterOptions = useMemo(() => {
    if (!allAppsData) {
      return defaultFilterOptions;
    }

    const modelTypeFilters = (finalValue?.flat() || []).map(filter => {
      const translatedValue = {
        dl: 'driving_license',
        basic: 'basic_phone',
        bvn: 'basic_bvn',
        advance: 'bvn',
        global_search: 'company_search',
      }[filter.value];

      return {
        ...filter,
        value: translatedValue || filter.value,
        label: `${Constants.lookups.getCountryAcronym(
          filter.name.replace('_id', ''),
        )} ${
          filter.value === 'account_number' ? 'Account Number' : filter.label
        }`,
      };
    });

    const uniqueModelTypeFilters = modelTypeFilters.reduce(
      (uniqueFilters, filter) => {
        if (
          !uniqueFilters.some(
            uniqueFilter => uniqueFilter.value === filter.value,
          )
        ) {
          uniqueFilters.push(filter);
        }
        return uniqueFilters;
      },
      [],
    );

    return [
      {
        title: 'Apps',
        name: 'apps',
        values: mapToOptionsArray(allAppsData),
      },
      {
        title: 'Mode',
        name: 'mode',
        values: mapToOptionsArray(filters?.modes),
      },
      {
        title: 'ID type',
        name: 'model_type',
        values: uniqueModelTypeFilters
          .filter(option => option.value !== 'cac')
          .map(filter => ({
            ...filter,
            value: filter?.value === 'cac_advance' ? 'cac' : filter?.value,
          })),
      },
      {
        title: 'Status',
        name: 'status',
        values: Constants.lookups.status,
      },
      {
        title: 'Country',
        name: 'country',
        values: COUNTRIES,
      },
    ];
  }, [allAppsData, finalValue]);

  const handleFilter = (selectedOptions, actionType) => {
    const {apps, status, model_type, country, mode, ...filteredOptions} =
      filterOutEmptyValues(selectedOptions);
    const appIds =
      filteredOptions.app_id || allAppsData?.map(app => app?.value).join(',');

    const appsForApiCall = apps
      ? apps
          .map((app, index) => (index === 0 ? app : `app_id=${app}`))
          .join('&')
      : '';

    const formattedArrayParam = (array, paramName) =>
      array
        ? array.map((value, index) =>
            index === 0 ? value : `${paramName}=${value}`,
          )
        : [];

    const formattedAppIds = appIds
      ? appIds.split(',').map(appId => `app_id=${appId}`)
      : [];
    const formattedStatus = formattedArrayParam(status, 'status');
    const formattedMode = formattedArrayParam(mode, 'mode');
    const formattedType = formattedArrayParam(model_type, 'model_type');
    const formattedCountry = formattedArrayParam(country, 'country');

    const modifiedStatus = status ? status.join(',') : '';
    const modifiedType = model_type ? model_type.join(',') : '';
    const modifiedCountry = country ? country.join(',') : '';

    const appIdArray = appsForApiCall.split('&');

    const appIdsUrl = appIdArray
      .filter(appId => appId !== 'app_id=')
      .map(appId => appId.replace('app_id=', ''));

    const formattedAppIdsUrl = `app_id=${appIdsUrl.join(',')}`;
    const queryParamsArray = [
      appsForApiCall && formattedAppIdsUrl,
      modifiedStatus && `status=${modifiedStatus}`,
      modifiedType && `model_type=${modifiedType}`,
      modifiedCountry && `country=${modifiedCountry}`,
      actionType === 'download' ? '' : `page=1`,
    ];

    const customDateParams = generateQueryParams(filteredOptions);
    if (customDateParams && actionType !== 'download') {
      queryParamsArray.push(customDateParams);
    }

    const queryParams = queryParamsArray.filter(Boolean).join('&');
    setCurrentPage(1);
    navigate(`?${queryParams}`);

    const apiParams = {
      company_id: userDetails?.company?.id,
      page: 1,
      limit,
      model_type: formattedType.join('&'),
      apps: formattedAppIds.join('&'),
      status: formattedStatus.join('&'),
      mode: formattedMode.join('&'),
      country: formattedCountry.join('&'),
      ...filteredOptions,
    };

    if (actionType === 'download') {
      apiParams.download = true;
    }

    if (appsForApiCall) {
      apiParams.apps = appsForApiCall;
    } else if (appIds) {
      if (formattedAppIds?.length > 0) {
        formattedAppIds[0] = formattedAppIds?.[0].replace('app_id=', '');
      }
      apiParams.apps = formattedAppIds.join('&');
    }

    getLookupHistory(apiParams);
    actionType !== 'download' && setFiltersApplied(true);

    setOpenFilter(false);
  };
  const handleResetFilter = () => {
    const app_ids = allAppsData?.map(app => app?.value).join('&app_id=');

    const apiParams = {
      company_id: userDetails?.company?.id,
      apps: app_ids,
      page: 1,
    };

    navigate('');
    setCurrentPage(1);
    getLookupHistory(apiParams);
    setOpenFilter(true);
    setFiltersApplied(false);
  };

  const isOwner = userDetails?.permissions?.role === 'Owner';
  const {performSingleIndividualLookup, downloadIndividualLookup} =
    useMemo(() => userDetails?.permissions?.lookupPermissions, [userDetails]) ||
    {};
  const performRight = isOwner ? false : !performSingleIndividualLookup;

  const handleRequestParams = (key, value) => {
    const searchParams = new URLSearchParams(document.location.search);
    searchParams.set(key, value);
    const newSearchParams = searchParams.toString();
    navigate(`?${newSearchParams}`);
  };

  const EmptyStateComponent = () => {
    const isLoading = h_loading || (env === 'Sandbox' && apiLoading);
    const isDisabled = !userDetails?.company?.verified || performRight;
    const isNoDataInSandbox =
      env === 'Sandbox' && !apiLoading && data?.length === 0;
    const isEmptyArray = !h_loading && !apiLoading && data?.length === 0;

    if (isLoading) {
      return <Loader height={50} />;
    }

    const commonEmptyStateProps = {
      onClick: () => setOpenIL(!openIndividualLookup),
      src: lookupEmpty,
      disabled: isDisabled,
      style: {cursor: isDisabled ? 'not-allowed' : 'pointer'},
      imageHeight: 48,
      imageWidth: 48,
      height: 55,
    };

    const renderEmptyState = (body, extraProps = {}) => (
      <EmptyState body={body} {...commonEmptyStateProps} {...extraProps} />
    );

    if (filtersApplied && isEmptyArray) {
      return renderEmptyState(
        'This filter did not return any data, try using different values.',
        {
          src: corruptedFile,
          noBtn: true,
          customBtn: (
            <button
              onClick={handleResetFilter}
              className="text-brandBlue p-4 text-sm font-medium"
            >
              Update preferences
            </button>
          ),
        },
      );
    }
    if ((isEmptyArray || isNoDataInSandbox) && !filtersApplied) {
      return renderEmptyState('No historical record available');
    }

    return null;
  };

  const transactionsSummary = [
    {
      icon: greenCheckBg,
      alt: 'successful',
      title: 'Successful',
      value: shortenLargeNumbers(analysisData?.success_count || 0, null, {
        startAt: 'M',
        decimals: 0,
      }),
    },
    {
      icon: failed,
      alt: 'failed',
      title: 'Failed',
      value: shortenLargeNumbers(analysisData?.failed_count || 0, null, {
        startAt: 'M',
        decimals: 0,
      }),
    },
  ];

  const tableCardData = buildTableCardData(
    {count: history?.count},
    transactionsSummary,
  );

  return (
    <DashboardLayout
      bg="bg-white80"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={easyLookupIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Easy lookup</p>
        </div>
      }
    >
      <EasyLookupModals
        open={openIndividualLookup}
        setOpen={setOpenIL}
        lookupType="Single"
        batch={false}
      />

      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={options => handleFilter(options, 'filter')}
        resetUrl={handleResetFilter}
        filterOptions={filterOptions}
      />

      <div className="flex items-center justify-between">
        <Link
          to={PAGES_URL.INDIVIDUAL_LOOKUP}
          className="flex items-center gap-2"
        >
          <img src={backNofill} alt="" />
          <span className="text-small text-grey80 font-medium -mb-1">Back</span>
        </Link>
      </div>

      {children ?? (
        <>
          <div className="flex pt-10 flex-col items-start justify-between gap-6 mt-4 mb-6 md:flex-row md:items-center md:gap-0 sm:mt-0">
            <h2 className="text-base font-medium text-grey sm:text-lg">
              Single Lookup history
            </h2>

            <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
              {env !== 'Sandbox' && (
                <>
                  <SearchInput
                    placeholder="Search history..."
                    onChange={value => {
                      setCurrentPage(1);
                      setSearchTerm(value);
                      setFiltersApplied(true);
                      handleRequestParams('search', value);
                    }}
                    defaultValue={searchTerm}
                  />
                  <FilterButton
                    openFilter={openFilter}
                    setOpenFilter={setOpenFilter}
                  />
                </>
              )}
              {downloadIndividualLookup && (
                <div className="flex flex-wrap items-center gap-4 ">
                  <PrimaryButton
                    buttonText="Export"
                    className="w-[100px]"
                    onClick={options => handleFilter(options, 'download')}
                    disabled={!downloadIndividualLookup || loading}
                    title={
                      !downloadIndividualLookup
                        ? 'You do not have permission to perform this action'
                        : ''
                    }
                    loading={loading}
                  />
                </div>
              )}
            </div>
          </div>

          <OverviewCards
            cardData={tableCardData}
            overviewLoading={analysisLoading || h_loading}
            className={`grid  md:grid-cols-${
              tableCardData?.length > 3 ? tableCardData?.length - 1 : 3
            } xl:grid-cols-${
              tableCardData?.length
            } gap-2 lg:gap-4 flex-wrap my-5`}
          />

          <TableLayout negativeMargins>
            <thead className="text-xs font-semibold uppercase">
              <tr className="bg-white80">
                {singleIndividualLookupTableHeader.map(({label, key}) => (
                  <th
                    key={key}
                    className={`${
                      label === 'Name'
                        ? 'p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap'
                        : 'p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap'
                    }`}
                  >
                    <div className="flex items-end gap-1 cursor-pointer">
                      <span>{label}</span>
                    </div>
                  </th>
                ))}

                <th></th>
              </tr>
            </thead>

            <tbody className="text-sm text-body">
              <tr>
                <td colSpan={5}>
                  <EmptyStateComponent />
                </td>
              </tr>
            </tbody>

            <tbody className="text-sm bg-white">
              {!h_loading &&
                data &&
                data?.map((record, i) => (
                  <tr
                    onClick={() => {
                      if (record.status_code !== '200') return;
                      setOpenIL(!openIndividualLookup);
                      changeLookupPage(2);
                      env !== 'Sandbox' &&
                        fetchLookup({
                          id: record?.entity_id,
                          type: record?.entity_type?.toLowerCase(),
                          history_id: record?.history_id,
                        });
                    }}
                    className="border-b cursor-pointer border-grey60"
                    key={i}
                  >
                    <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                      {`${record?.entity_name || '-'}`}
                    </td>
                    <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                      {/*{`${Constants.lookups.getCountryAcronym(*/}
                      {/*  record?.country?.toLowerCase(),*/}
                      {/*)} ${capitalizeEveryWord(*/}
                      {/*  record?.model_type === 'vnin'*/}
                      {/*    ? 'Virtual NIN '*/}
                      {/*    : record?.model_type === 'passport'*/}
                      {/*    ? 'International Passport'*/}
                      {/*    : record?.model_type === 'bvn'*/}
                      {/*    ? 'BVN Advance'*/}
                      {/*    : record?.model_type?.split('_')?.join(' '),*/}
                      {/*)}` || '-'}*/}
                      {record?.entity_type?.replaceAll('_', ' ') || '-'}
                    </td>
                    <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                      {/*{record.request_ids &&*/}
                      {/*Object.keys(record.request_ids).length > 0*/}
                      {/*  ? Object.entries(record.request_ids).map(*/}
                      {/*      ([key, value], index, array) => (*/}
                      {/*        <span key={key}>*/}
                      {/*          {value}*/}
                      {/*          {index !== array.length - 1 ? ', ' : ''}*/}
                      {/*        </span>*/}
                      {/*      ),*/}
                      {/*    )*/}
                      {/*  : '-'}*/}
                      {record?.id_number?.toUpperCase() || '-'}
                    </td>

                    <td className="p-4 pl-0 whitespace-nowrap">
                      <div
                        className={`px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit ${
                          // ['completed', '200']?.includes(
                          //   record?.status_code,
                          // )
                          //   ? 'text-success20 bg-success'
                          //   : ['failed', '400', '500']?.includes(
                          //       record?.status_code,
                          //     )
                          //   ? 'text-white bg-status-404'
                          //   : 'text-white bg-brandBlue'
                          errorCodeColor(record?.status_code)?.bg
                        } ${errorCodeColor(record?.status_code)?.color}`}
                      >
                        {checkStatus(record?.status_code) || '-'}
                      </div>
                    </td>
                    <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                      {moment(record?.date_created).format(
                        'MMM DD, YYYY hh:mmA',
                      ) || '-'}
                    </td>
                    {record.status === 'complete' && (
                      <td className="p-4 pl-0 whitespace-nowrap">
                        <div className="flex items-center gap-4">
                          <button
                            title="View"
                            onClick={() => {
                              setOpenIL(!openIndividualLookup);
                              changeLookupPage(2);
                              env !== 'Sandbox' &&
                                fetchLookup({
                                  id: record?.model_type_id,
                                  type: record?.model_type,
                                  history_id: record?.history_id,
                                });
                            }}
                            className="h-4 aspect-square"
                          >
                            <img src={eye} alt="" />
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </TableLayout>

          {!h_loading && data?.length !== 0 && (
            <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
              <div className="flex items-center text-body text-tiny">
                <PageLimit
                  onLimitChange={setLimit}
                  // className="mt-3 mt-sm-0"
                  total={history && history?.count}
                  length={history?.rows?.length}
                  limit={limit}
                />
              </div>

              <div className="mb-8 sm:mb-0">
                <Pagination
                  total={history && Math.ceil(history?.count / limit)}
                  current={+currentPage}
                  onPageChange={activePage => {
                    pageClick(activePage);
                    handleRequestParams('page', activePage);
                  }}
                />
              </div>
            </div>
          )}
        </>
      )}
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(IndividualSingleLookup);
