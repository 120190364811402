import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import IdleTimer from './helpers/idleTimer';
import Routes from './routes';
import {logout} from './store/actions';
import {DOJAH_SUB_DOMAINS} from './helpers';
import {Loader} from './components';
import {getSubDomainTheme} from './helpers/getSubDomainTheme';
import {MODULE} from './store/types';
import GoogleTranslate from './components/GoogleTranslate';

export default function Main() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState('en');
  useEffect(() => {
    if (navigator && navigator?.language?.startsWith('pt')) {
      setLanguage('pt');
    }
  }, [language]);
  useEffect(() => {
    localStorage.removeItem('subdomain');
    dispatch({type: MODULE, payload: null});
    const hostname = window.location.hostname;
    // const hostname = 'reconcilo-dev-app.dojah.services';
    if (!DOJAH_SUB_DOMAINS.includes(hostname)) {
      getSubDomainTheme(hostname, setLoading, dispatch);
    }
  }, [dispatch]);

  useEffect(() => {
    const timer = new IdleTimer({
      onExpired: () => {
        dispatch(logout());
      },
      onTimeout: () => {
        dispatch(logout());
      },
      timeout: 3600,
    });

    return () => {
      timer.cleanUp();
    };
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {language === 'pt' && <GoogleTranslate />}
          <Routes />
        </>
      )}
    </>
  );
}
