import axios from 'axios';
import moment from 'moment';
import {SET_USER} from '../store/types';
import {capitalizeEveryWord} from './capitalizeEveryWord';
import * as Constants from './constants';
import {debounceFunction} from './debounceFunction';
import {getDateRange} from './getDateRange';
import {getCookieValue} from './getCookie';
import {customDateFilters} from './customDateFilters';
import {getStatusColor, getSubCategoriesColor} from './getStatusColor';
import {toast} from 'react-toastify';

const passwordCharacterCheck = [
  {
    name: 'lowercase letters',
    check: string => /(?=.*[a-z])/.test(string),
  },
  {
    name: 'uppercase letter',
    check: string => /(?=.*[A-Z])/.test(string),
  },
  {
    name: 'numbers',
    check: number => /(?=.*[0-9])/.test(number),
  },
  {
    name: '8 characters minimum',
    check: string => /(?=.{8,})/.test(string),
  },
  {
    name: 'special character (e.g. !,@,#,?,])',
    check: symbol => /(?=.*[^\w\s])/.test(symbol),
  },
];

const emailRegex = /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/;
const passwordRegex =
  /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).{7,}$/;

const getAppName = (apps, id) =>
  (apps && apps.find(app => app._id || app.value === id)?.name) || '';

const signUpIndustry = [
  {value: 'Financial Services', label: 'Financial Services'},
  {value: 'Crypto/Blockchain', label: 'Crypto/Blockchain'},
  {value: 'Logistics', label: 'Logistics'},
  {value: 'E-commerce', label: 'E-commerce'},
  {value: 'Lending', label: 'Lending'},
  {value: 'Agriculture', label: 'Agriculture'},
  {value: 'Health', label: 'Health'},
  {value: 'Sportbetting', label: 'Sportbetting'},
  {value: 'Real estate', label: 'Real estate'},
  {value: 'Education', label: 'Education'},
];

const signUpServices = [
  {value: 'identity lookup', label: 'Identity Lookup'},
  {value: 'selfie verification', label: 'Selfie Verification'},
  {value: 'aml screening', label: 'AML Screening'},
  {value: 'address verification', label: 'Address Verification'},
  {value: 'financial data', label: 'Financial Data'},
  {value: 'messaging', label: 'Messaging'},
];

const token = {
  get: type => window.localStorage.getItem(type || 'user:token'),
  remove: type => window.localStorage.removeItem(type || 'user:token'),
  set: (newToken, type) =>
    window.localStorage.setItem(type || 'user:token', newToken),
};

const api = axios.create({baseURL: process.env.REACT_APP_BASE_URL});

api.interceptors.request.use(config => {
  const sessionUserToken = sessionStorage.getItem('tempToken');

  const userToken = `Bearer ${token?.get() || sessionUserToken}`;
  const envData = getCookieValue('env');

  userToken && (config.headers.Authorization = userToken);
  // config.headers['Content-Type'] = 'application/json';
  config.headers.Environment = envData || token.get('env') || 'Sandbox';

  return config;
});

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return 'Something went wrong!!';
  }
};

const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    //
  }
};

const onSignupSuccessHelper = (data, callback, navigate, location) => {
  const subDomainUser = localStorage.subdomain;
  const projectId = sessionStorage.getItem('project_id') || 'add-project';

  if (data) {
    const {token: userToken, user, hasCreatedCompany} = data;
    token.set(userToken);

    const intercomAppId =
      process.env.REACT_APP_APP_ENV_MODE === 'production'
        ? process.env.REACT_APP_INTERCOM_PROD_APP_ID
        : process.env.REACT_APP_INTERCOM_DEV_STAGING_APP_ID;

    callback({
      payload: {
        ...user,
        hasCreatedCompany,
        intercomAppId,
        intercomHash: data?.intercomHash,
      },
      type: SET_USER,
    });
    if (!navigate) return;

    if (hasCreatedCompany) {
      if (subDomainUser) {
        if (subDomainUser?.includes('reconcilo')) {
          return (window.location.href =
            Constants.PAGES_URL.PROJECT_ACCOUNTS?.replace(':slug', projectId));
        }
        return (window.location.href =
          Constants.PAGES_URL.DEVELOPERS_CONFIGURATION);
      }
      if (location?.state?.from) {
        window.location.href = `${location?.state?.from?.pathname}${location?.state?.from?.search}`;
      } else {
        window.location.href = Constants.PAGES_URL.QUICKACTIONS;
      }
    } else if (navigate) {
      navigate(Constants.PAGES_URL.SIGNIN);
    }
  }
};

const enforceFraudCheckConfigurations = rules =>
  Object.values(rules).every(nestedObj =>
    Object.values(nestedObj).every(value => {
      if (Array.isArray(value)) {
        return !!value.length;
      }
      if (typeof value === 'boolean') {
        return true;
      }
      return !!value;
    }),
  );
const checkStatus = status => {
  switch (status?.toLowerCase()) {
    case 'complete':
    case '200':
      return 'Successful';

    case 'pending':
      return 'Pending';

    case 'failed':
    case '400':
    case '401':
    case '404':
    case '500':
      return 'Failed';

    default:
      return status?.toLowerCase();
  }
};

const runOnce = (function (callback) {
  let executed = false;
  return function () {
    if (!executed) {
      executed = true;
      callback();
    }
  };
})();

const errorCodeColor = code => {
  switch (code) {
    case '400':
    case '401':
    case '404':
    case '424':
    case '500':
      return {bg: 'bg-statusBg-500', color: 'text-status-500'};

    case '200':
    case '201':
    case '202':
      return {bg: 'bg-statusBg-200', color: 'text-status-200'};
  }
};

const handleFileInput = (file, callback, max_file_size) => {
  if (file) {
    const fileSizeBytes = file.size;
    const fileSizeMB = fileSizeBytes / (1024 * 1024) || 0;

    if (max_file_size && parseFloat(fileSizeMB?.toFixed(2)) > max_file_size) {
      return toast.error(
        `Document uploaded is too large. Max file size should be ${max_file_size}MB`,
      );
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const data = {
        file: reader.result,
        filename: file.name,
        type: file.type,
      };
      callback(data);
    };
  }
};

const calculateFileSize = size => {
  let fileSize = size;
  const sizeUnits = ['B', 'KB', 'MB', 'GB'];
  let fileSizeString = `${fileSize} ${sizeUnits[0]}`;
  let i = 0;
  while (fileSize >= 1024 && i < sizeUnits.length - 1) {
    fileSize /= 1024;
    i++;
    // eslint-disable-next-line no-unused-vars
    fileSizeString = `${fileSize.toFixed(2)} ${sizeUnits[i]}`;
  }
  return fileSizeString;
};

function openFileInNewTab(dataURI) {
  if (!dataURI) {
    throw new Error('Data URI is empty or invalid.');
  }
  const mimeType =
    dataURI.match(/^data:(.*?);/)?.[1] || 'application/octet-stream';
  const content = mimeType?.startsWith('image/')
    ? `<div style="display: flex; justify-content: center; align-items: center"><img src="${dataURI}" alt="File"></div>`
    : mimeType === 'text/plain'
    ? `<pre>${dataURI}</pre>`
    : mimeType === 'application/pdf'
    ? `<iframe src="${dataURI}" width="100%" height="100%"></iframe>`
    : (() => {
        throw new Error(`Unsupported file type: ${mimeType}`);
      })();

  const newTab = window.open();
  if (newTab) {
    newTab.document.write(content);
    newTab.document.close();
  } else {
    throw new Error(
      'Failed to open a new tab. Please check your browser settings.',
    );
  }
}

const handleImage = (e, callback, type) => {
  const reader = new FileReader();
  e && reader.readAsDataURL(e);
  reader.onload = function () {
    callback({loading: true});
    api
      .post(`/users/upload`, {image: reader.result, type})
      .then(res =>
        callback({loading: null, icon: res.data.image, name: e.name}),
      )
      .catch(err => console.error(err));
  };
};

const truncate = (string, n) =>
  string?.length > n ? `${string.substring(0, n - 1)}...` : string;

const AppEnv = Object.freeze({
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
  STAGING: 'staging',
});

const url =
  process.env.REACT_APP_APP_ENV_MODE === AppEnv.PRODUCTION
    ? 'https://dojah.io'
    : process.env.REACT_APP_APP_ENV_MODE === AppEnv.DEVELOPMENT
    ? 'https://dev-web.dojah.services'
    : 'https://staging-web.dojah.services';

const calculatePredefinedPeriod = paramValue => {
  let endDate = moment().endOf('day');
  let startDate;

  if (paramValue === 'yesterday') {
    startDate = moment().subtract(1, 'days').startOf('day');
    endDate = startDate.clone().endOf('day');
  } else if (paramValue === 'last_7_days') {
    startDate = moment().subtract(6, 'days').startOf('day');
  } else if (paramValue === 'last_15_days') {
    startDate = moment().subtract(14, 'days').startOf('day');
  } else if (paramValue === 'last_30_days') {
    startDate = moment().subtract(29, 'days').startOf('day');
  } else {
    startDate = moment().startOf('day');
  }

  return {
    start: startDate.format('YYYY-MM-DD HH:mm:ss'),
    end: endDate.format('YYYY-MM-DD HH:mm:ss'),
  };
};

const parseUrlParams = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const apiParams = {};

  for (const [paramName, paramValue] of queryParams.entries()) {
    if (paramName === 'start' || paramName === 'end') {
      apiParams[paramName] = paramValue;
    } else if (paramName === 'period') {
      const predefinedPeriod = calculatePredefinedPeriod(paramValue);
      apiParams.start = predefinedPeriod.start;
      apiParams.end = predefinedPeriod.end;
    } else if (paramName.endsWith('_start')) {
      const optionName = paramName.slice(0, -7);
      if (!apiParams[optionName]) {
        apiParams[optionName] = {};
      }
      apiParams[optionName].start = paramValue;
    } else if (paramName.endsWith('_end')) {
      const optionName = paramName.slice(0, -5);
      if (!apiParams[optionName]) {
        apiParams[optionName] = {};
      }
      apiParams[optionName].end = paramValue;
    } else {
      apiParams[paramName] = paramValue;
    }
  }

  return apiParams;
};

const formatDuration = seconds => {
  const duration = moment.duration(seconds, 'seconds');
  const minutes = duration.minutes();
  const remainingSeconds = duration.seconds();

  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const generateQueryParams = selectedOptions => {
  const queryParams = new URLSearchParams();
  const paramValues = {};
  let periodAppended = false;

  for (const optionName in selectedOptions) {
    const optionValue = selectedOptions[optionName];

    if (Array.isArray(optionValue) && optionValue.length > 0) {
      paramValues[optionName] = optionValue;
    } else if (optionValue && !periodAppended) {
      const {start, end} = customDateFilters({
        value: optionValue,
        appFilters: selectedOptions,
        onCheck: () => {},
      });

      const daysDifference = moment(end).diff(moment(start), 'days') + 1;

      let option = 'custom_range';

      if (daysDifference === 1 && moment(end).isSame(moment(), 'day')) {
        option = 'today';
      } else if (moment(start).isSame(moment().subtract(1, 'days'), 'day')) {
        option = 'yesterday';
      } else if (daysDifference === 7 && moment(end).isSame(moment(), 'day')) {
        option = 'last_7_days';
      } else if (daysDifference === 15 && moment(end).isSame(moment(), 'day')) {
        option = 'last_15_days';
      } else if (daysDifference === 30 && moment(end).isSame(moment(), 'day')) {
        option = 'last_30_days';
      }

      if (option === 'custom_range') {
        queryParams.append(optionName, encodeURIComponent(optionValue));
      } else {
        queryParams.append('period', option);
        periodAppended = true;
      }
    }
  }

  for (const paramName in paramValues) {
    const paramValue = paramValues[paramName].map(value =>
      encodeURIComponent(value),
    );
    queryParams.append(paramName, paramValue.join(','));
  }

  return queryParams.toString().replace(/\+/g, '_').toLowerCase();
};

const filterOutEmptyValues = selectedOptions =>
  Object.fromEntries(
    Object.entries(selectedOptions).filter(([, value]) => {
      if (Array.isArray(value) && value.length === 0) {
        return false;
      }
      if (
        typeof value === 'object' &&
        value !== null &&
        Object.keys(value).length === 0
      ) {
        return false;
      }
      if (value === null || value === undefined) {
        return false;
      }
      return value.toString() !== '';
    }),
  );

const formatStr = str => {
  const length = str.length;
  if (length <= 25) {
    return str.toUpperCase();
  }

  return `${str.substring(0, 5)}${'*'.repeat(
    Math.max(length - 25, 0),
  )}${str.substring(length - 15)}`.toUpperCase();
};

const mapToOptionsArray = (items, valueKey = '_id') =>
  items?.map(item => {
    if (typeof item === 'string') {
      return {
        name: item,
        label: item,
        value: item?.replace(/ /g, '_').toLowerCase() || item,
      };
    } else if (typeof item === 'object') {
      const originalValue =
        item?.[valueKey] ||
        (item?.convoy_subscription_id ? item?.convoy_subscription_id : '');
      const transformedValue = formatStr(originalValue);
      return {
        name: item?.name || transformedValue,
        label: item?.name || transformedValue,
        value:
          transformedValue?.replace(/ /g, '_').toLowerCase() || item?.value,
      };
    }
    return null;
  }) || [];

const DOJAH_SUB_DOMAINS = [
  'localhost',
  'app.dojah.io',
  'dev-dojah-app-v3.dojah.services',
  'staging-dojah-app-v3.dojah.services',
];
const getInitials = (name, count = 2) => {
  if (typeof name !== 'string') return '';
  const words = name.trim().split(/\s+/);
  if (words.length === 0) return '';
  const [first, ...rest] = words;
  const last = rest.pop();
  return ((first?.[0] || '') + (last?.[0] || '')).toUpperCase().slice(0, count);
};

const setLocalStorageData = (key, value) => localStorage.setItem(key, value);

function separateCamelCaseWithCaps(text) {
  return text
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

function allObjectValuesFalsy(obj) {
  return Object.values(obj).every(value => {
    if (typeof value === 'object' && value !== null) {
      return allObjectValuesFalsy(value);
    }
    return !value;
  });
}

export {
  passwordRegex,
  allObjectValuesFalsy,
  enforceFraudCheckConfigurations,
  passwordCharacterCheck,
  onSignupSuccessHelper,
  signUpIndustry,
  signUpServices,
  token,
  api,
  emailRegex,
  getInitials,
  loadState,
  saveState,
  checkStatus,
  Constants,
  debounceFunction,
  handleFileInput,
  handleImage,
  capitalizeEveryWord,
  getDateRange,
  errorCodeColor,
  runOnce,
  getAppName,
  truncate,
  url,
  calculateFileSize,
  openFileInNewTab,
  parseUrlParams,
  formatDuration,
  generateQueryParams,
  filterOutEmptyValues,
  formatStr,
  mapToOptionsArray,
  getStatusColor,
  getSubCategoriesColor,
  setLocalStorageData,
  separateCamelCaseWithCaps,
  DOJAH_SUB_DOMAINS,
};
