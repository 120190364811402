/* eslint-disable react/jsx-indent */
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {useQuery} from 'react-query';
import {toast} from 'react-toastify';
import * as Actions from '../../store/actions';
import {
  DashboardLayout,
  PrimaryButton,
  TableLayout,
  Pagination,
  FilterButton,
  PageLimit,
  Filter,
  EmptyState,
  SearchInput,
  Loader,
} from '../../components';
import {
  corruptedFile,
  customer360Ic,
  eye,
  lookupEmpty,
  nigeriaIc,
  southAfricaIc,
} from '../../assets/images/images';
import {
  filterOutEmptyValues,
  generateQueryParams,
  parseUrlParams,
} from '../../helpers';
import {PAGES_URL} from '../../helpers/constants';
import {useAllApps} from '../../hooks/useApps';
import {fetchBusinessPool} from '../../requests/queries/business360';
import EasyLookupModals from '../EasyLookup/EasyLookupModals';
import {getIDType} from '../Customer360/LayoutConstants';
import moment from 'moment';
import {OverviewCards} from '../../components/OverviewCards';
import {buildTableCardData} from '../../helpers/buildTableCardData';

const filterOptions = [
  {
    title: 'Status',
    name: 'status',
    values: [
      {
        label: 'Valid',
        value: 'matched',
        name: 'matched',
      },
      {
        label: 'Invalid',
        value: 'mismatched',
        name: 'mismatched',
      },
    ],
  },
];

const tableHeader = [
  {
    label: 'Business Name',
    key: 'first_name',
  },
  {
    label: 'Business ID',
    key: 'customer_id',
  },
  {
    label: 'countries',
    key: 'countries',
  },
  {
    label: 'ID type',
    key: 'id_type',
  },
  // {
  //   label: 'Date created',
  //   key: 'date_created',
  // },
  {
    label: 'date updated',
    key: 'date_updated',
  },
];

const initialFilters = {
  start: '',
  end: '',
  status: '',
};

function Business360() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState(initialFilters);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const {userApps} = useAllApps();

  const allApps = userApps?.map(app => app.value)?.join(',');

  const {data: businessPool, isLoading} = useQuery(
    ['business-pool', {page: currentPage, limit, ...filters}],
    () =>
      fetchBusinessPool(allApps, {
        page: currentPage,
        limit,
        ...filters,
      }),
    {
      onSuccess: data => {
        toast.success(data.message);
        setFilters({
          ...filters,
          download: undefined,
        });
      },
      onError: error => {
        toast.error(error.response.data.error);
      },
      enabled: !!allApps,
      retry: 1,
    },
  );

  const businessPoolData = businessPool?.rows;

  const getCountryLogo = country =>
    ({
      nigeria: nigeriaIc,
      ng: nigeriaIc,
      'south-africa': southAfricaIc,
      sa: southAfricaIc,
    }[country?.toLowerCase() || 'nigeria']);

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    const apiParams = parseUrlParams();
    apiParams.limit = limit;

    const searchParams = new URLSearchParams(document.location.search);
    const pageParam = searchParams.get('page');
    if (pageParam) {
      setCurrentPage(pageParam);
    }

    const statusParam = searchParams.get('status');
    const searchParam = searchParams.get('search');
    if (statusParam) {
      apiParams.status = statusParam;
    }

    if (searchParam) {
      apiParams.search = searchParam;
    }

    if (
      !apiParams.start &&
      !apiParams.end &&
      (!apiParams.search || apiParams.search === '') &&
      (!apiParams.status || apiParams.status === '')
    ) {
      setFiltersApplied(false);
    } else {
      setFiltersApplied(true);
    }

    if (Object.keys(apiParams).length > 1) {
      setFilters(apiParams);
    }
  }, [limit]);

  const handleBusinessLookup = verification => {
    navigate(
      PAGES_URL.BUSINESS_360_BUSINESS_LOOKUP.replace(':slug', verification.id),
      {
        state: {entityTypes: verification.entity_types.split(',')},
      },
    );
  };
  const handleFilter = (selectedOptions, shouldDownload = false) => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);
    const queryParams = generateQueryParams(filteredOptions);

    const {status} = filteredOptions;

    if (Array.isArray(status)) {
      filteredOptions.status = status.join(',');
    }

    setCurrentPage(1);
    let baseUrl = '';

    if (queryParams) {
      baseUrl += `?${queryParams}`;
    }

    if (shouldDownload) {
      filteredOptions.download = true;
      setFilters({
        ...filters,
        ...filteredOptions,
      });
    } else {
      navigate(baseUrl);
    }

    setFilters(filteredOptions);
    setFiltersApplied(true);
    setOpenFilter(false);
  };
  const handleResetFilter = () => {
    navigate('');
    setCurrentPage(1);
    setFilters(initialFilters);
    setFiltersApplied(false);
    setOpenFilter(true);
  };

  const tableCardData = buildTableCardData({
    count: businessPool?.count,
  });

  return (
    <DashboardLayout
      bg="bg-white80"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={customer360Ic} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Business 360</p>
        </div>
      }
    >
      {/*<PerformLookupModal open={open} setOpen={setOpen}  />*/}

      <EasyLookupModals
        open={open}
        setOpen={setOpen}
        lookupType="Single"
        batch={false}
        lookupCategory="Business"
        business360={true}
      />
      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={handleFilter}
        resetUrl={handleResetFilter}
        filterOptions={filterOptions}
      />
      <div className="flex pt-5 flex-col items-start justify-between gap-6 mt-4 mb-6 md:flex-row md:items-center md:gap-0 sm:mt-0">
        <h2 className="text-base font-medium text-grey sm:text-lg">
          Business 360
        </h2>

        <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
          <SearchInput
            onChange={value => {
              const searchParams = new URLSearchParams(
                document.location.search,
              );

              if (value === '') {
                searchParams.delete('search');
              } else {
                searchParams.set('search', value);
                setFiltersApplied(true);
              }

              const newSearchParams = searchParams.toString();
              navigate(`?${newSearchParams}`);
              setFilters({...filters, search: value});
            }}
            className="outline-grey60"
          />

          <FilterButton openFilter={openFilter} setOpenFilter={setOpenFilter} />

          <div
            className="fixed bottom-0 left-0 right-0 z-10 px-4 py-2 overflow-x-hidden sm:hidden bg-white80"
            style={{
              boxShadow: '0px -1px 8px rgba(51, 52, 66, 0.25)',
            }}
          >
            <PrimaryButton
              buttonText="Perform Business Lookup"
              xPadding="p-[35px]"
              onClick={() => setOpen(!open)}
              className="w-full bottom-2"
            />
          </div>
        </div>
      </div>

      <OverviewCards
        cardData={tableCardData}
        overviewLoading={isLoading}
        className={`grid grid-cols-2 md:grid-cols-3 xl:grid-cols-${tableCardData.length} gap-2 lg:gap-4 flex-wrap my-5`}
      />

      <TableLayout negativeMargins>
        <thead className="text-xs font-semibold uppercase">
          <tr className="bg-white80">
            {tableHeader.map(({label, key}) => (
              <th
                key={key}
                className={`${
                  label === 'Business Name'
                    ? 'p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap'
                    : 'p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap'
                }`}
              >
                <div className="flex items-end gap-1 cursor-pointer">
                  <span>{label}</span>
                </div>
              </th>
            ))}

            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={8}>
              {isLoading ? (
                <Loader height={50} />
              ) : !isLoading &&
                businessPoolData?.length === 0 &&
                !filtersApplied ? (
                <EmptyState
                  body="Perform multiple identity lookup with ease."
                  src={lookupEmpty}
                  bodyMaxWidth="max-w-[203px]"
                  noBtn
                  customBtn={
                    <PrimaryButton
                      buttonText="Add Business"
                      className="mt-2 w-fit"
                      onClick={() => setOpen(!open)}
                    />
                  }
                />
              ) : businessPoolData?.length === 0 &&
                !isLoading &&
                filtersApplied ? (
                <EmptyState
                  body={
                    'This filter did not return any data, try using different values.'
                  }
                  src={corruptedFile}
                  noBtn
                  customBtn={
                    <button
                      onClick={handleResetFilter}
                      className="text-brandBlue p-4 text-sm font-medium"
                    >
                      Update preferences
                    </button>
                  }
                />
              ) : null}
            </td>
          </tr>
        </tbody>

        <tbody className="text-sm bg-white">
          {!isLoading && businessPoolData &&
            businessPoolData?.map(verification => (
              <tr
                onClick={() => handleBusinessLookup(verification)}
                className="border-b cursor-pointer border-grey60"
                key={verification.id}
              >
                <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                  {verification?.business_name || ''}
                </td>
                <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap uppercase">
                  {verification?.business_number || ''}
                </td>
                <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                  <div className="flex items-center -space-x-[6px]">
                    {verification?.countries &&
                      [verification?.countries]?.map((country, i) => (
                        <div
                          key={i}
                          className="w-5 h-5 flex items-center justify-center bg-blue-50 outline outline-1 outline-blue-100 rounded-full"
                        >
                          <img
                            src={getCountryLogo(country?.toLowerCase())}
                            alt=""
                            width={12}
                            height={8}
                          />
                        </div>
                      ))}
                  </div>
                </td>
                <td className="p-4 pl-0 whitespace-nowrap">
                  {getIDType(verification?.entity_types)}
                  {/*<div*/}
                  {/*  className={`px-2 pt-[5px] pb-[3px] text-sm font-medium uppercase rounded-full w-fit `}*/}
                  {/*>*/}
                  {/*  */}
                  {/*</div>*/}
                </td>
                {/*<td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">*/}
                {/*  {moment(verification?.date_created).format(*/}
                {/*    'Do MMM, YYYY h:mm A',*/}
                {/*  )}*/}
                {/*</td>*/}
                <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                  {moment(verification?.updatedAt).format(
                    'Do MMM, YYYY h:mm A',
                  )}
                </td>
                <td className="p-4 pl-0 whitespace-nowrap">
                  <div className="flex items-center gap-4">
                    <button
                      title="View"
                      onClick={() => {
                        navigate(
                          PAGES_URL.BUSINESS_360_BUSINESS_LOOKUP.replace(
                            ':slug',
                            verification.id,
                          ),
                        );
                      }}
                      className="h-4 aspect-square"
                    >
                      <img src={eye} alt="" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </TableLayout>
      {businessPoolData && businessPoolData.length > 0 && (
        <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
          <div className="flex items-center text-body text-tiny">
            <PageLimit
              onLimitChange={setLimit}
              total={businessPool && businessPool?.totalRecords}
              length={businessPoolData?.length}
              limit={limit}
            />
          </div>

          <div className="mb-8 sm:mb-0">
            <Pagination
              total={
                businessPoolData &&
                Math.ceil(businessPool?.totalRecords / limit)
              }
              current={+currentPage}
              onPageChange={activePage => {
                pageClick(activePage);
                const searchParams = new URLSearchParams(
                  document.location.search,
                );
                searchParams.set('page', activePage);
                const newSearchParams = searchParams.toString();
                navigate(`?${newSearchParams}`);
              }}
            />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(Business360);
