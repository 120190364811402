import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {
  apiCalls,
  apiLogsIc,
  chevronRight,
  failed,
  successful,
  question,
} from '../../assets/images/images';
import {
  Card,
  DashboardLayout,
  Filter,
  FilterButton,
  PageLimit,
  Pagination,
  PrimaryButton,
  TableLayout,
} from '../../components';
import * as Actions from '../../store/actions';
import Loader from '../../components/Loader';
import EmptyState from '../../components/EmptyState';
import {
  errorCodeColor,
  filterOutEmptyValues,
  generateQueryParams,
  parseUrlParams,
} from '../../helpers';
import {renderServices} from '../../helpers/renderServicies';
import {PopOver} from '../../components/PopOver';
import {toast} from 'react-toastify';
import {useQuery} from 'react-query';
import {fetchApiLogsFilters} from '../../requests/queries/dashboard';
import {
  exportApiLogs,
  fetchApiLogs,
} from '../../requests/queries/Developers/apiLogs';
import {PageContext} from '../../helpers/context';
import {useAllApps} from '../../hooks/useApps';

const defaultFilterOptions = [
  {
    title: 'Apps',
    name: 'apps',
    values: [],
  },
  {
    title: 'Mode',
    name: 'mode',
    values: [],
  },
  {
    title: 'Status code',
    name: 'status_code',
    values: [],
  },
  {
    title: 'Services',
    name: 'services',
    values: [],
  },
];

function DevelopersApiLogs({auth: {userDetails}}) {
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [exportLogs, setExportLogs] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('developers');
  }, [setPageName]);

  const apiParams = parseUrlParams();
  const {userApps} = useAllApps();

  apiParams.limit = limit;
  const {data: filters, isLoading} = useQuery(['filters'], fetchApiLogsFilters);

  const {data: logs, isLoading: logsLoading} = useQuery(
    ['api-logs', {...apiParams}],
    () => fetchApiLogs(apiParams),
  );
  const {
    isLoading: exportLogsLoading,
    isFetching: exportLogsFetching,
    refetch,
  } = useQuery(
    ['api-logs', {...apiParams, exportLogs}],
    () => exportApiLogs(apiParams),
    {
      enabled: exportLogs,
    },
  );

  const handleExportLogs = useCallback(async () => {
    setExportLogs(true);
    try {
      const result = await refetch();
      toast.success(result?.data?.message || '');
      if (!exportLogsLoading || !exportLogsFetching) {
        setExportLogs(false);
      }
    } catch (e) {
      console.log(e);
    }
  }, [exportLogsLoading, exportLogsFetching, refetch]);

  const getAppName = useCallback(
    appId =>
      !isLoading && userApps && userApps?.find(app => app.value === appId),
    [isLoading, userApps],
  );

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const pageParam = searchParams.get('page');
    if (pageParam) {
      setCurrentPage(parseInt(pageParam));
      apiParams.page = parseInt(pageParam);
    } else {
      apiParams.page = currentPage;
    }

    if (
      Object.keys(apiParams).length === 2 &&
      'limit' in apiParams &&
      'page' in apiParams
    ) {
      setFiltersApplied(false);
    } else {
      setFiltersApplied(true);
    }
  }, [currentPage, limit, apiParams]);

  const filterOptions = useMemo(() => {
    if (!isLoading && !filters) {
      return defaultFilterOptions;
    }

    const mapToOptionsArray = (items, valueKey = '_id') =>
      items?.map(item => ({
        name: getAppName(item)?.label || item,
        label: getAppName(item)?.label || item,
        value: item?.[valueKey] || item?.replace(/ /g, '_')?.toLowerCase(),
      })) || [];

    return [
      {
        title: 'Apps',
        name: 'apps',
        values: mapToOptionsArray(filters?.apps, 'value'),
      },
      {
        title: 'Mode',
        name: 'mode',
        values: mapToOptionsArray(filters?.modes),
      },
      {
        title: 'Status code',
        name: 'status_code',
        values: mapToOptionsArray(filters?.statusCodes),
      },
      {
        title: 'Services',
        name: 'services',
        values: mapToOptionsArray(filters?.services),
      },
    ];
  }, [filters, isLoading, getAppName]);

  const handleFilter = selectedOptions => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);

    try {
      const {start, end, ...rest} = filteredOptions;
      const queryParams = generateQueryParams(rest);
      setOpenFilter(false);
      setCurrentPage(1);
      navigate(
        `?${queryParams}&page=1${start ? `&start=${start}` : ''}${
          end ? `&end=${end}` : ''
        }`,
      );
      setFiltersApplied(true);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleResetFilter = () => {
    navigate('');
    setCurrentPage(1);
    setOpenFilter(true);
  };
  const isOwner = useMemo(
    () => userDetails.permissions.role === 'Owner',
    [userDetails.permissions.role],
  );

  const {viewAndExportLogs: canViewAndExportLogs} = useMemo(
    () => userDetails?.permissions?.developerPermissions || {},
    [userDetails],
  );

  const statusCodeMeanings = useMemo(
    () => [
      {
        code: 200,
        meaning: 'successful',
      },
      {
        code: 201,
        meaning: 'empty strings',
      },
      {
        code: 400,
        meaning: 'Bad Request',
      },
      {
        code: 401,
        meaning: 'unauthorized secret keys',
      },
      {
        code: 402,
        meaning: 'Low Balance',
      },
      {
        code: 424,
        meaning: 'Unable to reach service',
      },
      {
        code: 403,
        meaning: 'session not found',
      },
      {
        code: 404,
        meaning: 'not found',
      },
      {
        code: 405,
        meaning: 'method not allowed',
      },
      {
        code: 500,
        meaning: 'server error',
      },
      {
        code: 502,
        meaning: 'gateway timeout',
      },
    ],
    [],
  );

  return (
    <DashboardLayout
      bg="bg-white"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={apiLogsIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Developers</p>
          <img src={chevronRight} alt="" width={18} height={18} />
          <p className="-mb-1 cursor-pointer">API Logs</p>
        </div>
      }
    >
      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={handleFilter}
        resetUrl={handleResetFilter}
        filterOptions={filterOptions}
      />
      <section className="flex flex-col">
        <div className="flex items-center justify-between w-full">
          <h2 className="text-base font-medium text-grey sm:text-lg">
            Api Logs
          </h2>
          <div className="flex items-center gap-4">
            <FilterButton
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
            />
            <PrimaryButton
              loading={exportLogsLoading || exportLogsFetching}
              onClick={handleExportLogs}
              buttonText="Export"
              style={{
                width: '80px',
              }}
              disabled={!canViewAndExportLogs}
            />
          </div>
        </div>
        {(canViewAndExportLogs || isOwner) && (
          <div className="flex items-center gap-4 my-6 overflow-x-auto whitespace-nowrap">
            <Card
              img={apiCalls}
              title="APIs Created"
              body={logs?.summary?.total || 0}
              loading={logsLoading}
            />
            <Card
              img={successful}
              title="Successful API Calls"
              body={logs?.summary?.successful || 0}
              loading={logsLoading}
            />
            <Card
              img={failed}
              title="Failed API calls"
              body={logs?.summary?.failed || 0}
              loading={logsLoading}
            />
          </div>
        )}
        <div className="self-end my-5">
          <PopOver
            show={isShowing}
            header={
              <div
                onMouseEnter={() => setIsShowing(true)}
                onMouseLeave={() => setIsShowing(false)}
                className="flex items-center justify-center"
              >
                <h1 className="text-brandBlue mr-2">
                  What do these codes mean?{' '}
                </h1>
                <img src={question} alt="question mark" />
              </div>
            }
          >
            <div>
              {statusCodeMeanings.map(({code, meaning}) => (
                <p
                  key={meaning}
                  className={`uppercase py-2 ${
                    code.toString().includes('20')
                      ? 'text-success'
                      : code.toString().includes('50')
                      ? 'text-danger'
                      : 'text-grey80'
                  }`}
                >{`${code} - ${meaning}`}</p>
              ))}
            </div>
          </PopOver>
        </div>
        <TableLayout negativeMargins negativeRightMargin="-mr-4 xl:-mr-[76px]">
          <thead className="text-xs font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-4 text-xs font-medium text-left sm:pl-6 xl:pl-12 text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>App Name</span>
                </div>
              </th>
              <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>mode</span>
                </div>
              </th>
              <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>Services</span>
                </div>
              </th>
              <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>date/time</span>
                </div>
              </th>
              <th className="p-5  pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>Status</span>
                </div>
              </th>
              {/* <th className="w-[5.6%]"></th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={8}>
                {logsLoading ? (
                  <Loader height={45} />
                ) : !logsLoading &&
                  (!canViewAndExportLogs || logs?.api_logs?.length === 0) ? (
                  !filtersApplied ? (
                    <EmptyState
                      body={
                        !canViewAndExportLogs
                          ? 'You do not have access to this resource'
                          : 'No API logs found.'
                      }
                      noBtn
                      height={45}
                    />
                  ) : (
                    <EmptyState
                      body="No results found"
                      noBtn
                      customBtn={
                        <button
                          onClick={handleResetFilter}
                          className="text-brandBlue p-4 text-sm font-medium"
                        >
                          Update preferences
                        </button>
                      }
                      height={45}
                    />
                  )
                ) : null}
              </td>
            </tr>
          </tbody>
          <tbody className="text-sm bg-white">
            {logs?.api_logs &&
              logs?.api_logs?.map((log, i) => (
                <tr className="border-b border-grey60 logs-tr" key={i}>
                  <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                    {getAppName(log?.app)?.label || ['-']}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {log?.mode || 'API Call'}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap capitalize">
                    {renderServices(log?.service) ||
                      log?.service?.replaceAll('_', ' ') ||
                      '-'}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {moment(log?.createdAt).format('Do MMM, YYYY h:mm A')}
                  </td>
                  <td className="w-[17.6%] pr-0 text-tiny text-body whitespace-nowrap">
                    <div
                      className={`flex px-2  py-1 text-xs font-medium uppercase rounded-full cursor-pointer w-fit logs-code ${
                        errorCodeColor(log?.status_code)?.color
                      } ${errorCodeColor(log?.status_code)?.bg}`}
                    >
                      {log?.status_code || '-'}
                      <span className="before:content-['\00a0'] animate-dropdown">
                        - {log?.raw_status}
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </TableLayout>
        {(canViewAndExportLogs || isOwner) &&
          !logsLoading &&
          logs?.totalRecords !== 0 && (
            <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
              <div className="flex items-center text-body text-tiny">
                <PageLimit
                  onLimitChange={setLimit}
                  total={logs && logs?.totalRecords}
                  length={logs?.api_logs?.length}
                  limit={limit}
                />
              </div>

              <div className="mb-8 sm:mb-0">
                <Pagination
                  total={logs && Math.ceil(logs?.totalRecords / limit)}
                  current={+currentPage}
                  onPageChange={activePage => {
                    pageClick(activePage);
                    const searchParams = new URLSearchParams(
                      document.location.search,
                    );
                    searchParams.set('page', activePage);
                    const newSearchParams = searchParams.toString();
                    navigate(`?${newSearchParams}`);
                  }}
                />
              </div>
            </div>
          )}
      </section>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(DevelopersApiLogs);
