/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import * as EmailValidator from 'email-validator';

import {connect} from 'react-redux';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {
  chevronRight,
  closeWidget,
  easyOnboardIc,
  edit,
  exclamationMark,
  logo,
  widgetBack,
} from '../../assets/images/images';
import {DashboardLayout, PrimaryButton, SkeletonLoader} from '../../components';

import * as Actions from '../../store/actions';
import {PreviewScreens} from './PreviewScreens';
import {Constants} from '../../helpers';
import ButtonLoader from '../../components/ButtonLoader';
import {toast} from 'react-toastify';
import ConfigPanel from './ConfigPanel';
import {getPages} from '../../helpers/getPages';
import inverseColor from '../../helpers/colorInversion';

import {EasyOnboardContext} from '../../helpers/context/EasyOnboardContext';
import {PlayIc} from '../../components/images';
import {
  getFraudRules,
  getNewPagesConfig,
  setPricingForOtherPages,
  getMinMaxPricing,
} from './widgetHelpers';
import DeleteFlowModal from './ConfirmFlowDelete';
import ConfirmUnSaveFlowModal from './UnsavedPrompt';
import {useBlockNavigation} from '../../hooks/useBlockNavigation';
import {useAllApps} from '../../hooks/useApps';
import {shortenLargeNumbers} from '../../helpers/shortenLargeNumbers';

function UpdateFlow({
  widgets,
  auth: {userDetails},
  getWidget,
  updateWidget,
  createWidget,
  previewWidgetCode,
  setSingleApp,
  setWidgetTotalCurrentPricing,
  setWidgetCurrentPricing,
  setWidgetPages,
}) {
  const [selectedApp, setSelectedApp] = useState('');
  const {widgetData, totalCost, setWidgetData, trackChanges, setTrackChanges} =
    useContext(EasyOnboardContext);
  const isLoading = useAllApps()?.isLoading;

  const [
    {deleteModal, saveType, nameError, currentPage, typeCall, pages},
    setState,
  ] = useReducer((state, action) => ({...state, ...action}), {
    deleteModal: false,
    saveType: '',
    nameError: false,
    currentPage: '',
    setType: '',
    pages: '',
  });

  const textAreaRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const widgetId = location?.search?.split('=')[1];

  const hasWidgetUrl = !!localStorage.subdomain && !!userDetails?.widgetUrl;
  const customWidgetUrl = hasWidgetUrl
    ? userDetails?.widgetUrl
    : userDetails?.company?.widget_url;

  const {alertModal, setAlertModal, nextLocation, setNextLocation} =
    useBlockNavigation();

  const WIDGET_ID_LENGTH = 24;
  const minimumMultipleOptions = 3;

  const pageCurrentMaxPrice = getMinMaxPricing(
    widgets?.widgetScreen,
    widgets?.widgetPricing,
    widgets?.widgetCurrentPricing,
  ).max;

  const pageCurrentMinPrice = getMinMaxPricing(
    widgets?.widgetScreen,
    widgets?.widgetPricing,
    widgets?.widgetCurrentPricing,
  ).min;

  const displayPrice = (condition, price) =>
    condition &&
    shortenLargeNumbers(
      price || 0,
      userDetails?.company?.currency || 'NGN',
      {},
    );

  useEffect(() => {
    if (!widgets?.w_loading && widgets?.singleWidget?.widget) {
      setSingleApp(widgets?.singleWidget?.widget?.app);

      setWidgetData(prev => ({
        ...prev,
        name: widgets?.singleWidget?.widget?.name || '',
        ...(widgets?.singleWidget?.widget?.index_instructions
          ? {
              index_instructions:
                widgets?.singleWidget?.widget?.index_instructions,
            }
          : {}),
      }));
    }
  }, [widgets?.w_loading, widgets?.singleWidget?.widget?.name]);

  useEffect(() => {
    if (
      !widgets?.widgetCurrentTotalPricing ||
      Object.keys(widgets?.widgetCurrentTotalPricing).length === 0 ||
      widgets?.widgetPages?.length === 0
    ) {
      setWidgetTotalCurrentPricing(widgets?.widgetCurrentTotalPricing);
      setWidgetPages(widgets?.widgetPages);
    }

    return () => setWidgetTotalCurrentPricing({});
  }, [widgets?.w_loading]);

  useEffect(() => {
    if (widgetId?.length === WIDGET_ID_LENGTH) {
      getWidget(widgetId, navigate);
    }
  }, [widgetId]);

  useEffect(() => {
    setPricingForOtherPages(
      widgets?.widgetPages,
      widgets?.widgetPricing,
      setWidgetCurrentPricing,
    );
    return () => setWidgetCurrentPricing({});
  }, [widgets?.widgetPages, widgets?.widgetScreen?.page]);

  const data = useMemo(
    () => widgets?.defaultPages?.find(widget => widget?.pageId === currentPage),
    [currentPage, widgets?.defaultPages],
  );
  const fraudData = useMemo(
    () =>
      widgets?.fraudDefaultPages?.find(widget => widget?.page === currentPage),
    [currentPage],
  );

  const newPages = getNewPagesConfig(widgets?.defaultPages).filter(
    p => p.page !== 'indemnity',
  );

  const widgetName = widgetData?.name || widgets?.singleWidget?.widget?.name;
  const FRAUD_RULES = getFraudRules(widgets?.fraudRules);
  const hasGovtData = !!newPages.find(page => page.page === 'government-data');
  const hasQuestions = newPages.find(page => page.page === 'custom-questions');
  const checkOptions = hasQuestions?.config?.questions?.find(q =>
    ['multiple', 'single'].includes(q?.type),
  );

  const publishWidget = useCallback(
    (type, preview) => {
      preview ? setState({typeCall: 'preview'}) : setState({typeCall: type});
      const newType = type === 'draft' ? '' : type === 'unpublish' ? '' : type;
      if (!newPages.length) {
        return toast.error('Please add verification page(s)');
      }

      if (checkOptions?.options?.length < minimumMultipleOptions) {
        return toast.error('Options should NOT be less than three (3).');
      }

      if (hasQuestions?.config?.title === '') {
        return toast.error('Question(s) title is required');
      }

      if (!widgetName || widgetName?.includes('Untitled')) {
        setState({nameError: true});
        return toast.error(
          `${
            widgetName?.includes('Untitled')
              ? 'Name cannot be "Untitled", please update'
              : 'Please name your widget flow'
          }`,
        );
      }
      if (
        widgetData?.support_email &&
        !EmailValidator.validate(widgetData?.support_email)
      ) {
        return toast.error('Support email is not valid');
      }

      if (
        FRAUD_RULES?.age_limit?.limit < 16 ||
        FRAUD_RULES?.age_limit?.limit > 90
      ) {
        return toast.error('Age limit must be between 16 and 90');
      }

      if (hasGovtData)
        toast.success(
          <div className="flex items-center justify-center">
            <span className=" mr-3 w-9 h-6 bg-white rounded-full flex items-center justify-center">
              <img src={exclamationMark} alt="Info" width={18} />
            </span>
            <p className="text-[12px]">
              Please note, government data is only available for specific
              countries.
            </p>
          </div>,
        );
      const WIDGET_DATA = {
        ...widgetData,
        name: widgetName,
        published: !!newType,
        pages: [...newPages],
        rules: FRAUD_RULES,
      };

      const message =
        newType === 'publish'
          ? 'Widget published successfully'
          : 'Widget saved successfully';
      if (newPages) {
        widgets?.singleWidget && widgets?.singleWidget?.widget?._id
          ? updateWidget(
              {
                id: widgets?.singleWidget?.widget?._id,
                to: nextLocation,
                navigate,
                ...WIDGET_DATA,
              },
              message,
              newType,
            )
          : createWidget({
              app: selectedApp,
              update: {...WIDGET_DATA},
              message,
              type: newType,
              preview,
              navigate,
              to: nextLocation,
            });
      }
      setTrackChanges(false);
    },
    [
      widgets?.singleWidget,
      updateWidget,
      widgetData,
      nextLocation,
      newPages,
      FRAUD_RULES,
      trackChanges,
    ],
  );

  const handleChangeName = e => {
    const {value} = e.target;
    setState({nameError: false});
    setWidgetData({...widgetData, name: value});
    setTrackChanges(true);
  };

  const handleFocus = () => {
    textAreaRef.current.focus();
    textAreaRef.current.select();
  };

  const handlePreview = () => {
    if (widgetId && widgetId?.length >= WIDGET_ID_LENGTH) {
      setState({typeCall: 'preview'});
      widgetId && previewWidgetCode(widgetId, customWidgetUrl);
    } else {
      setState({typeCall: 'preview'});
      publishWidget('draft', 'preview');
    }
  };

  const {viewFlows: canViewFlows, manageFlows: canManageFlows} =
    useMemo(
      () => userDetails?.permissions?.onboardPermissions,
      [userDetails],
    ) || {};

  return (
    <DashboardLayout
      fullScreen
      topPadding="pt-0"
      xlLeftMargin="xl:ml-12"
      xlRightPadding="sm:pr-0"
      breadCrumbs={
        <div className="flex items-center">
          <img src={easyOnboardIc} alt="" width={18} height={18} />
          <Link to={Constants.PAGES_URL.EASY_ONBOARD} className="ml-2 -mb-1">
            Easy onboard
          </Link>
          <img src={chevronRight} alt="" width={18} height={18} />
          <p className="-mb-1 cursor-pointer">Update - {widgetData?.name}</p>
        </div>
      }
    >
      {typeCall !== '' && widgets?.w_loading && (
        <div className="fixed  left-0 top-0 z-50  w-full h-full flex items-center justify-center">
          <ButtonLoader color="#3F7CDB" />
          <span className="text-sm mt-16 text-body font-medium italic">
            ...
            {typeCall === 'changes'
              ? 'updat'
              : typeCall === 'draft'
              ? 'sav'
              : 'load'}
            ing
          </span>
        </div>
      )}

      <ConfirmUnSaveFlowModal
        handleSave={async () => {
          await publishWidget(saveType);
          setAlertModal(false);
        }}
        handleExit={() => {
          setAlertModal(false);
          setTrackChanges(false);
          navigate(nextLocation);
        }}
        alertModal={alertModal}
        setAlertModal={() => {
          setAlertModal(!alertModal);
          setNextLocation('');
        }}
        isLoading={widgets?.w_loading}
      />

      <DeleteFlowModal
        deleteModal={deleteModal}
        setDeleteModal={setState}
        flowId={widgetId}
        redirect={true}
      />
      <div
        className="flex bg-[#f9f9f9] relative basis-full h-[calc(100vh-65px)] overflow-hidden"
        style={{
          opacity:
            ((typeCall !== '' && widgets?.w_loading) || isLoading) && 0.5,
          height: 'calc(100vh - 65px)',
        }}
      >
        <div className="pt-6 pr-12 basis-[35%] pb-24 overflow-y-auto">
          <div className="flex items-center ">
            <input
              ref={textAreaRef}
              className={`text-lg font-medium ${
                !widgetName ? 'text-grey40 italic' : 'text-grey'
              } outline-none focus:outline focus:outline-offset-2 focus:outline-1 ${
                nameError
                  ? 'outline outline-danger outline-offset-2 outline-2'
                  : ''
              }  `}
              type="text"
              value={widgetData?.name}
              onChange={handleChangeName}
              style={{
                maxWidth: 350,
                maxHeight: 50,
                border: 'none',
              }}
            />

            <img
              src={edit}
              onClick={handleFocus}
              alt=""
              className="ml-2 cursor-pointer"
            />
          </div>

          <ConfigPanel
            setSelectedApp={setSelectedApp}
            setWidgetData={setWidgetData}
            setCurrentPage={setState}
            setPages={setState}
            pages={pages}
            widgetData={widgetData}
          />
        </div>

        <div className="bg-black25 relative basis-[65%] overflow-y-auto">
          <div className="bg-white80 fixed flex w-[53%] z-20 mr-6 right-0 items-center gap-4 py-2 px-12 justify-end">
            <div className="-mb-1 mr-auto flex items-center justify-center p-2 font-medium text-sm">
              Total Price:{' '}
              {widgets?.w_loading ? (
                <span className=" ml-2">
                  <SkeletonLoader
                    style={{
                      width: '100%',
                      height: 20,
                      maxWidth: 150,
                      margin: 0,
                    }}
                  />
                </span>
              ) : totalCost.min === totalCost.max ? (
                `${displayPrice(
                  widgets?.widgetPages?.length > 0 || totalCost?.max,
                  totalCost.max / 100 || 0,
                )}`
              ) : (
                `${displayPrice(
                  widgets?.widgetPages?.length > 0 || totalCost?.min,
                  totalCost.min / 100 || 0,
                )} - ${displayPrice(
                  widgets?.widgetPages?.length > 0 || totalCost?.max,
                  totalCost.max / 100 || 0,
                )}`
              )}
            </div>
            {!widgets?.w_loading &&
              !widgets?.singleWidget?.widget?.published && (
                <button
                  onClick={() => {
                    publishWidget('draft');
                    setState({saveType: 'draft'});
                  }}
                  className="p-2 text-xs font-medium rounded text-grey outline outline-1 outline-grey80"
                  disabled={canViewFlows && !canManageFlows}
                >
                  {typeCall === 'draft' && widgets?.w_loading
                    ? 'Saving...'
                    : 'Save as draft'}
                </button>
              )}
            <PrimaryButton
              buttonText={
                widgets?.singleWidget?.widget?.published
                  ? 'Apply changes'
                  : 'Publish'
              }
              xPadding="px-2"
              yPadding="pt-[9px] pb-[7px]"
              fontSize="text-xs"
              onClick={() => {
                publishWidget(
                  widgets?.singleWidget?.widget?.published
                    ? 'changes'
                    : 'publish',
                );
                setState({saveType: 'publish'});
              }}
              loading={
                typeCall === 'publish' ||
                (typeCall === 'changes' && widgets?.w_loading)
              }
              style={{width: '90px', height: '30px'}}
              disabled={canViewFlows && !canManageFlows}
              className="h-7"
            />
            <button
              onClick={handlePreview}
              disabled={typeCall === 'preview' && widgets?.w_loading}
              style={{
                opacity: typeCall === 'preview' && widgets?.w_loading ? 0.5 : 1,
                cursor:
                  typeCall === 'preview' && widgets?.w_loading
                    ? 'not-allowed'
                    : 'pointer',
              }}
              className="px-2 pt-[9px] pb-[7px] flex items-end gap-1 text-xs font-medium rounded text-brandBlue"
            >
              <PlayIc />
              {typeCall === 'preview' && widgets?.w_loading
                ? 'Processing...'
                : ' Preview widget'}
            </button>
            {widgetId?.length >= WIDGET_ID_LENGTH &&
              !widgets?.singleWidget?.widget?.published &&
              !widgets?.singleWidget?.widget?.hasBeenPublished && (
                <button
                  onClick={() => {
                    setState({typeCall: 'delete'});
                    // deleteWidget(widgets?.singleWidget?.widget?._id);
                    setState({deleteModal: true});
                  }}
                  className="px-2 pt-[9px] pb-[7px] text-xs text-white bg-danger rounded font-medium"
                  disabled={canViewFlows && !canManageFlows}
                >
                  {typeCall === 'delete' && widgets?.w_loading
                    ? 'Deleting...'
                    : 'Delete'}
                </button>
              )}

            {widgets?.singleWidget?.widget?.published &&
              widgets?.singleWidget?.widget?.hasBeenPublished && (
                <button
                  onClick={() => {
                    setState({typeCall: 'unpublish'});
                    publishWidget('unpublish');
                  }}
                  className="px-2 pt-[9px] pb-[7px] text-xs text-white bg-danger rounded font-medium"
                  disabled={canViewFlows && !canManageFlows}
                >
                  {typeCall === 'unpublish' && widgets?.w_loading
                    ? 'Unpublishing...'
                    : 'Unpublish'}
                </button>
              )}
          </div>

          {widgets?.screenType === 'verifications' ? (
            <div
              className="min-h-screen"
              style={{
                opacity:
                  widgets?.widgetScreen?.page === 'indemnity'
                    ? 1
                    : data &&
                      data[
                        `${widgets?.widgetScreen?.page}-${widgets?.widgetScreen?.pageId}`
                      ]
                    ? 1
                    : 0.3,
                transition: 'opacity 0.3s ease-in-out',
              }}
            >
              <div
                className={`flex flex-col items-center justify-center max-w-lg mx-auto ${
                  widgets?.widgetScreen?.page !== 'custom-questions'
                    ? 'h-screen py-4 '
                    : 'py-14'
                }`}
              >
                {!['user-data', 'indemnity', 'custom-questions'].includes(
                  widgets?.widgetScreen?.page,
                ) && (
                  <div className="bg-white mb-6 p-2 text-sm rounded">
                    <p>
                      {getPages(widgets?.widgetScreen?.page)}:
                      <span className="text-brandBlue ml-2">
                        {['government-data'].includes(
                          widgets?.widgetScreen?.page,
                        ) &&
                          `${displayPrice(
                            data?.[
                              `${widgets?.widgetScreen?.page}-${widgets?.widgetScreen?.pageId}`
                            ],
                            pageCurrentMinPrice || 0,
                          )} - `}
                        {displayPrice(
                          data?.[
                            `${widgets?.widgetScreen?.page}-${widgets?.widgetScreen?.pageId}`
                          ],
                          pageCurrentMaxPrice || 0,
                        )}
                      </span>
                    </p>
                  </div>
                )}
                {widgets?.widgetScreen?.page === 'custom-questions' &&
                  PreviewScreens[widgets?.widgetScreen?.page || 'indemnity']}
                {widgets?.widgetScreen?.page !== 'custom-questions' && (
                  <div className="flex flex-col items-center w-full pb-10 bg-white rounded">
                    <div className="flex justify-between w-full">
                      <button disabled>
                        <img src={widgetBack} alt="" className="pt-4 pl-4" />
                      </button>
                      <button disabled>
                        <img src={closeWidget} alt="" className="pt-4 pr-4" />
                      </button>
                    </div>
                    <header className="flex flex-col items-center mt-2">
                      <img
                        src={widgets?.widgetPages?.logo || ''}
                        alt=""
                        className="max-h-10"
                      />
                    </header>
                    {PreviewScreens[widgets?.widgetScreen?.page || 'indemnity']}
                    {widgets?.widgetScreen?.page !== 'upload-other-ids' && (
                      <button
                        disabled
                        className="w-full h-12 max-w-sm mt-6 font-medium  rounded text-tiny bg-brandBlue"
                        style={{
                          backgroundColor:
                            widgets?.widgetPages?.color ||
                            widgets?.widgetPages?.color_code ||
                            '',
                          color: inverseColor(
                            widgets?.widgetPages?.color ||
                              widgets?.widgetPages?.color_code ||
                              '#ffffff',
                          ),
                        }}
                      >
                        Continue
                      </button>
                    )}
                    {!userDetails?.company?.is_partner_company && (
                      <div className="flex items-center justify-center gap-2 my-4">
                        <span className="text-sm text-grey">Powered by</span>
                        <img src={logo} alt="" width={30.55} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : null}

          {widgets?.screenType === 'fraud' ? (
            <div
              className="min-h-screen"
              style={{
                opacity: fraudData && fraudData.isActive ? 1 : 0.3,
                transition: 'opacity 0.3s ease-in-out',
              }}
            >
              {PreviewScreens[widgets?.widgetScreen?.page || 'fraud-check']}
            </div>
          ) : null}

          {widgets?.screenType === 'integration' ? (
            <div className="min-h-screen">
              {PreviewScreens[widgets?.widgetScreen?.page || 'web_sdk']}
            </div>
          ) : null}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(UpdateFlow);
